import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import accentless from 'app/utils/functions/accentless';
import * as _ from 'lodash';
import { Thematicslistshare } from '../../../../../api/models';

@Component({
    selector: 'app-themes-list-modal',
    templateUrl: './themes-list-modal.component.html',
    styleUrls: ['./themes-list-modal.component.scss'],
})
export default class ThemesListModalComponent implements OnInit {
    @Input()
        allThematics: Thematicslistshare;

    @Input()
        selectedThematics = [];

    @Input()
    private dashboardService: DashboardService;

    @Output()
        addThemes = new EventEmitter<any>();

    public activeBtnValid: boolean = false;

    public deleteBtnActive: boolean = false;

    public sharedCollapse: boolean = true;

    public sharedInDashboardCollapse: boolean = true;

    public personalCollapse: boolean = true;

    displayColumns: Thematicslistshare = {
        personal: [],
        sharedInDashboard: [],
        shared: [],
    };

    get checkedThematics() {
        let checkedThem = [];
        ['personal', 'shared', 'sharedInDashboard'].forEach((type) => {
            if (this.allThematics[type].length > 0) {
                const thematicsTypeChecked = this.allThematics[type].filter((t) => t.checked);
                if (thematicsTypeChecked.length > 0) {
                    checkedThem = [...checkedThem, ...thematicsTypeChecked];
                }
            }
        });
        return checkedThem;
    }

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {
        this.activeBtnValid = false;

        this.sharedCollapse = this.getCollapse('shared');
        this.sharedInDashboardCollapse = this.getCollapse('sharedInDashboard');
        this.personalCollapse = this.getCollapse('personal');

        ['personal', 'shared', 'sharedInDashboard'].forEach((type) => {
            if (this.allThematics[type].length > 0) {
                this.initThematics(this.allThematics[type], type);
            }
        });

        this.displayColumns = JSON.parse(JSON.stringify(this.allThematics));
        this.updateSpressAllBtn();
    }

    /**
     * Méthode permettant d'enrichir les objects thématiques pour la recherche, la sélection, ...
     */
    initThematics(themes, type) {
        if (themes.length > 0) {
            themes.forEach((row) => {
                if (!Object.prototype.hasOwnProperty.call(row, 'them_definition') || row.them_definition === null || row.them_definition === '') {
                    row.them_definition = [];
                }
                row.search = [row.them_name, row.them_definition.join(' | ')].join(' | ');
                row.checked = false;
                row.isCollapsed = true;
                // Ajoute la propriété "type_sharing" pour que l'on sache dans la partie filtre d'où proviennent les thématiques
                if (type === 'personal') {
                    row.type_sharing = '0';
                } else {
                    row.type_sharing = '1';
                }
                if (this.selectedThematics.length > 0) {
                    this.selectedThematics.forEach((st) => {
                        if (st.them_id === row.them_id) {
                            row.checked = true;
                            row.selected = st.selected || false;
                        }
                    });
                }
            });
        }
    }

    /**
     * Evènement exécuté lorsque l'utilisateur sélectionne ou déselectionne la case à cocher
     */
    toggleSelected(checkedTheme, type) {
        const findedThematics = this.allThematics[type].find((t) => t.them_id === checkedTheme.them_id);
        if (findedThematics) {
            findedThematics.checked = checkedTheme.checked;
            findedThematics.selected = checkedTheme.checked;
        }
        this.updateValidBtn();
    }

    toggleCollapse(value:boolean, key:string) {
        this.dashboardService.toggleCollapse(key, value);
        this[`${key}Collapse`] = value;
    }

    getCollapse(key:string): boolean {
        const saved = this.dashboardService.getCollapse(key);
        return saved === undefined ? true : saved;
    }

    suppressAll() {
        [
            this.displayColumns.personal,
            this.displayColumns.sharedInDashboard,
            this.displayColumns.shared,
            this.allThematics.personal,
            this.allThematics.sharedInDashboard,
            this.allThematics.shared,
        ].forEach((thematicCategory) => {
            thematicCategory.forEach((thematic) => {
                thematic.checked = false;
            });
        });

        this.displayColumns = _.cloneDeep(this.displayColumns);

        this.updateValidBtn();
    }

    updateSpressAllBtn() {
        this.deleteBtnActive = false;
        [this.displayColumns.personal, this.displayColumns.sharedInDashboard, this.displayColumns.shared].forEach((thematicCategory) => {
            thematicCategory.forEach((thematic) => {
                if (thematic.checked) {
                    this.deleteBtnActive = true;
                }
            });
        });
    }

    /**
     * Met à jour l'état du bouton Valider de la popin si l'on détecte une différence entre les éléments sélectionnés à l'ouverture de la popin et les éléments cochés de la popin
     */
    updateValidBtn() {
        this.updateSpressAllBtn();
        const selectedThematicsId = this.selectedThematics.filter((t) => t.selected).map((t) => t.them_id);
        const checkedThematicsId = this.checkedThematics.map((t) => t.them_id);

        this.activeBtnValid = selectedThematicsId
            .filter((sid) => !checkedThematicsId.includes(sid))
            .concat(checkedThematicsId.filter((cid) => !selectedThematicsId.includes(cid))).length > 0;
    }

    /**
     * Methode appelé lorsque l'on utilise le champ de recherche d'une thématique.
     * Permet d'afficher uniquement les thématiques qui contiennent la recherche demandée
     */
    onFreeSearch(search) {
        const displayColumns = {
            personal: [],
            sharedInDashboard: [],
            shared: [],
        };

        const findR = new RegExp(accentless(search), 'gimu');
        ['personal', 'shared', 'sharedInDashboard'].forEach((type) => {
            if (this.allThematics[type].length > 0) {
                this.allThematics[type].forEach((col) => {
                    if (col.search && findR.test(accentless(col.search))) {
                        displayColumns[type].push(col);
                    }
                });
            }
        });

        this.displayColumns.personal = [...displayColumns.personal];
        this.displayColumns.sharedInDashboard = [...displayColumns.sharedInDashboard];
        this.displayColumns.shared = [...displayColumns.shared];

        this.toggleCollapse(false, 'personal');
        this.toggleCollapse(false, 'sharedInDashboard');
        this.toggleCollapse(false, 'shared');
    }

    /**
     * Lors de la validation de la modale, envoi les thématiques sélectionnées
     */
    saveThemes() {
        this.addThemes.emit(this.checkedThematics);
    }
}
