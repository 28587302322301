/* eslint-disable no-underscore-dangle */
import {
    Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import FooterService from 'app/shared/components/footer/footer.service';
import CorpusService from 'app/utils/services/corpus.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderType } from 'app/api/models/folder';
import AiConfigService from '../corpus-aiconfig-service';
import AiconfigShareModalComponent from './components/aiconfig-share-modal/aiconfig-share-modal.component';

@Component({
    selector: 'corpus-aiconfigs',
    templateUrl: './corpus-aiconfigs.component.html',
    styleUrl: './corpus-aiconfigs.component.scss',
    providers: [AiConfigService],
})
/**
 * Le composant principal qui s'affiche via le menu burger -> Configurations IA
 */
export default class CorpusAiconfigsComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('deleteModal')
        deleteModal: TemplateRef<any>;

    @ViewChild('shareModal')
        shareModal: TemplateRef<any>;

    @Input()
        type: FolderType = FolderType.IaConfig;

    // indique si la configuration a changé depuis le passage en mode sélection
    selectionChanged: boolean = false;

    constructor(
        private corpusService: CorpusService,
        private footerService: FooterService,
        private modalService: NgbModal,
        private userService: UserService,
        private aiconfigService: AiConfigService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();
    }

    async ngOnInit() {
        const segments = this.router.url.split('/');
        const lastSegment = segments[segments.length - 1];

        if (lastSegment === 'thematics') {
            this.type = FolderType.Thematic;
        } else {
            this.type = FolderType.IaConfig;
        }

        this.footerService.visible(false);

        this.subs.sink = this.aiconfigService.selectedConfigurations.subscribe(() => {
            this.selectionChanged = this.checkSelectionChanged();
        });

        await this.aiconfigService.load(this.type);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.footerService.visible(true);
    }

    get trackType() {
        return this.type === FolderType.IaConfig ? 'configuration IA' : 'thématique';
    }

    get selectedConfigurations() {
        return this.aiconfigService.selectedConfigurations.getValue() || [];
    }

    get originalSelectedConfigurations() {
        return this.aiconfigService.originalSelectedConfigurations.getValue() || [];
    }

    get addConfigurationToFolderFromToolbar() {
        return this.aiconfigService.addConfigurationToFolderFromToolbar;
    }

    // ajout de configurations à un dossier
    async validateAddConfigurationsToFolder() {
        await this.aiconfigService.addSelectedConfigurationsToFolder();

        this.aiconfigService.collapsed.next([true, true, true]);

        this.aiconfigService.folders.getValue().forEach((folder) => {
            folder.state = folder.fold_id === this.aiconfigService.selectedFolder.getValue().fold_id;
        });
        this.aiconfigService.folders.next(this.aiconfigService.folders.getValue());

        this.aiconfigService.selectedConfigurations.next([]);
        this.aiconfigService.changeSelectionState(false, false, false);
    }

    // annule la sélection -> masque la barre de validation
    cancelSelection() {
        this.aiconfigService.changeSelectionState(false, false, false);
    }

    get addConfigurationToFolder() {
        return this.aiconfigService.addConfigurationToFolder;
    }

    get shareConfiguration() {
        return this.aiconfigService.shareConfiguration;
    }

    // eslint-disable-next-line class-methods-use-this
    defaultSort(datas) {
        if (!datas) {
            return [];
        }

        return _.uniq(datas.sort((rowA, rowB) => {
            const a = `${rowA.surname} ${rowA.givenname}`.toUpperCase();
            const b = `${rowB.surname} ${rowB.givenname}`.toUpperCase();

            return a.localeCompare(b);
        }));
    }

    showShareConfigurationModal() {
        const modal = this.modalService.open(AiconfigShareModalComponent, { size: '500', backdrop: 'static' });
        modal.componentInstance.corpusService = this.corpusService;
        modal.componentInstance.userService = this.userService;
        modal.componentInstance.aiconfigService = this.aiconfigService;

        modal.componentInstance.onSave.subscribe(() => {
            this.cancelSelection();
        });

        modal.componentInstance.onCancel.subscribe(() => {
            this.cancelSelection();
        });
    }

    checkSelectionChanged(): boolean {
        if (this.selectedConfigurations.length !== this.originalSelectedConfigurations.length) {
            return true;
        }

        const sortedArr1 = [...this.selectedConfigurations].sort((a, b) => a.id - b.id);
        const sortedArr2 = [...this.originalSelectedConfigurations].sort((a, b) => a.id - b.id);

        for (let i = 0; i < sortedArr1.length; i += 1) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return true;
            }
        }

        return false;
    }
}
