// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .btn-chat-dinootoo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
:host .btn-chat-dinootoo .btn {
  height: 60px;
  width: 60px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 3px rgba(0, 0, 0, 0.14);
  border-radius: 50px;
  color: #fff;
  border-width: 0;
}
:host .btn-chat-dinootoo .btn .text {
  text-align: left;
  font-size: 18px;
  width: 0;
  visibility: hidden;
}
:host .btn-chat-dinootoo .btn .vcrm-ai-generative {
  font-size: 36px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50px;
  display: block;
}
:host .btn-chat-dinootoo .btn:hover, :host .btn-chat-dinootoo .btn.hover {
  display: flex;
  width: 220px;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background-color: #f16e00;
}
:host .btn-chat-dinootoo .btn:hover .text, :host .btn-chat-dinootoo .btn.hover .text {
  width: auto;
  padding-left: 20px;
  visibility: visible;
}
:host .btn-chat-dinootoo .btn:hover .vcrm-ai-generative, :host .btn-chat-dinootoo .btn.hover .vcrm-ai-generative {
  background-color: #fff;
  color: #f16e00;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
