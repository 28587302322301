import {
    AfterViewInit,
    Component,
    Input, OnChanges, OnInit,
    SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Folder, FolderType } from 'app/api/models/folder';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import accentless from 'app/utils/functions/accentless';
import { CorpusService as CorpusApi, ThematicService } from 'app/api/services';
import { firstValueFrom } from 'rxjs';
import ThematicEditorComponent, { ThematicEditorMode } from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { Thematic } from 'app/api/models';
import AiconfigEditModalComponent from '../aiconfig-edit-modal/aiconfig-edit-modal.component';
import ConfigurationRow from '../configuration-row';

@Component({
    selector: 'aiconfig-folder-configurations',
    templateUrl: './aiconfig-folder-configurations.component.html',
    styleUrl: './aiconfig-folder-configurations.component.scss',
})
export default class AiconfigFolderConfigurationsComponent extends BaseComponent implements AfterViewInit, OnChanges, OnInit {
    @Input()
        folder: Folder;

    previousConfigurations: Array<IaConfigResponse>;

    filteredConfigurations: Array<ConfigurationRow>;

    @Input()
    private filter = '';

    public columns: Array<any> = [];

    @Input()
    public scrollable = true;

    @ViewChild('actionsRowTpl') actionsRowTpl: TemplateRef<any>;

    @ViewChild('sharedRowTpl') sharedRowTpl: TemplateRef<any>;

    @ViewChild('ownerRowTpl') ownerRowTpl: TemplateRef<any>;

    @ViewChild('detailRowTpl') detailRowTpl: TemplateRef<any>;

    @ViewChild('favRowTpl') favRowTpl: TemplateRef<any>;

    @ViewChild('favColTpl') favColTpl: TemplateRef<any>;

    @ViewChild('selectRowTpl') selectRowTpl: TemplateRef<any>;

    @ViewChild('headerSelectTpl') headerSelectTpl: TemplateRef<any>;

    @ViewChild('selectOneRowTpl') selectOneRowTpl: TemplateRef<any>;

    @ViewChild('originRowTpl') originRowTpl: TemplateRef<any>;

    constructor(private modalService: NgbModal, private translateService: TranslateService, private aiConfigService: AiConfigService, private corpusApi: CorpusApi, private thematicService: ThematicService) {
        super();
    }

    get configurations() {
        return this.folder.content || [];
    }

    ngOnInit(): void {
        this.subs.sink = this.aiConfigService.selectionStateChange.subscribe(() => {
            this.initColumns();
        });

        this.subs.sink = this.aiConfigService.folders.subscribe(() => {
            this.applyFilters();
        });

        this.subs.sink = this.aiConfigService.closeAllConfigurationDetails.subscribe(() => {
            this.filteredConfigurations.forEach((row: ConfigurationRow) => {
                row.isCollapsed = true;
            });
        });

        this.previousConfigurations = [...this.configurations];
        this.applyFilters();
    }

    get selection() {
        return this.aiConfigService.selection[0] || this.aiConfigService.selection[1] || this.aiConfigService.selection[2];
    }

    get entityType() : FolderType {
        return this.aiConfigService.type;
    }

    applyFilters() {
        this.filteredConfigurations = [];
        const findR = new RegExp(accentless(this.filter), 'gimu');

        this.configurations.forEach((configuration) => {
            findR.lastIndex = 0;
            if (findR.test(accentless(configuration.search))) {
                this.filteredConfigurations.push(new ConfigurationRow(configuration));
            }
        });
    }

    ngAfterViewInit(): void {
        this.initColumns();
    }

    public get config(): any {
        return {
            hasDetails: true,
            detailTemplate: this.detailRowTpl,
            collapseAllDetailsTooltipToTranslate: 'translations.analysisDashboard.themes.details_tooltip',
            style: {
                maxHeight: this.scrollable ? '400px' : 'unset',
                'overflow-y': this.scrollable ? 'scroll' : 'unset',
            },
        };
    }

    get favo() {
        return {
            prop: 'is_favorite',
            cellTemplate: this.favRowTpl,
            headerTemplate: this.favColTpl,
            title: 'F',
            sort: true,
            style: {
                width: '50px',
            },
        };
    }

    private name = {
        prop: 'name',
        title: 'translations.utils.name',
        sort: true,
        style: {
            'word-break': 'break-word',
        },
    };

    get user() {
        return {
            prop: 'usernameOwner',
            cellTemplate: this.ownerRowTpl,
            title: 'translations.analysisDashboard.themes.author',
            sort: true,
            style: {
                width: '200px',
            },

        };
    }

    private update = {
        prop: 'update_date',
        title: 'translations.analysisDashboard.themes.last_update',
        sort: true,
        style: {
            width: '150px',
        },

    };

    private lang = {
        prop: 'lang',
        title: 'translations.thematics.language',
        sort: true,
        style: {
            width: '150px',
        },

    };

    get sharedate() {
        return {
            prop: 'sharing_date',
            title: `translations.analysisDashboard.${this.type}.share_date`,
            sort: true,
            style: {
                width: '150px',
            },
        };
    }

    get shared() {
        return {
            title: `translations.analysisDashboard.${this.type}.shared_column`,
            cellTemplate: this.sharedRowTpl,
            prop: 'shared',
            sort: true,
            style: {
                width: '50px',
            },

        };
    }

    get actions() {
        return {
            title: '',
            cellTemplate: this.actionsRowTpl,
            style: {
                width: '70px',
            },
        };
    }

    get origin() {
        return {
            prop: 'origin',
            title: `translations.analysisDashboard.${this.type}.origin`,
            cellTemplate: this.originRowTpl,
            sort: true,
            style: {
                width: '150px',
            },
        };
    }

    get selectionCol() {
        return {
            headerTemplate: this.headerSelectTpl,
            cellTemplate: this.selectRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    get selectionOneCol() {
        return {
            title: '',
            cellTemplate: this.selectOneRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.applyFilters();
        }
    }

    get selectOnlyOne() {
        return this.aiConfigService.selectOnlyOne;
    }

    isSelected(configuration) {
        return this.aiConfigService.selectedConfigurations.getValue().indexOf(configuration) >= 0;
    }

    isAllSelected() {
        return this.filteredConfigurations.every((row: ConfigurationRow) => this.aiConfigService.selectedConfigurations.getValue().indexOf(row.content) >= 0 || !row.perso);
    }

    toggleAll(value) {
        const selectedConfigurations = [...this.aiConfigService.selectedConfigurations.getValue()];
        this.filteredConfigurations.forEach((row: ConfigurationRow) => {
            const index = selectedConfigurations.indexOf(row.content);
            if (value && index < 0 && row.perso) {
                selectedConfigurations.push(row.content);
            } else if (!value && index >= 0) {
                selectedConfigurations.splice(index, 1);
            }
        });
        this.aiConfigService.selectedConfigurations.next(selectedConfigurations);
    }

    async copy(configuration) {
        await this.aiConfigService.copyIntoFolder(configuration, this.folder);
    }

    get type() : FolderType {
        return this.aiConfigService.type;
    }

    get folderColumns() {
        return this.aiConfigService.getFolderColumns().map((columnName) => this[columnName]);
    }

    initColumns() {
        this.columns = [];

        this.columns.push(this.favo);

        if (this.selectOnlyOne) {
            this.columns = [this.selectionOneCol];
        }

        this.columns = [...this.columns, ...this.folderColumns];

        if (this.aiConfigService.selection[0]) {
            this.columns = [this.selectionCol, ...this.columns];
        }

        if (!this.aiConfigService.hideActions) {
            this.columns.push(this.actions);
        }
    }

    toggleSelection(configuration) {
        const selectedConfigurations = [...this.aiConfigService.selectedConfigurations.getValue()];
        const index = selectedConfigurations.indexOf(configuration);
        if (index > -1) {
            selectedConfigurations.splice(index, 1);
        } else {
            selectedConfigurations.push(configuration);
        }
        this.aiConfigService.selectedConfigurations.next(selectedConfigurations);
    }

    remove(configuration: IaConfigResponse) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = `translations.analysisDashboard.${this.type}.delete_configuration_modal_title`;

        if (configuration.hidden) {
            modal.componentInstance.content = this.translateService.instant(
                `translations.analysisDashboard.${this.type}.delete_hidden_configuration_from_folder_modal_text`,
                { name: configuration.name },
            );
        } else {
            modal.componentInstance.content = this.translateService.instant(`translations.analysisDashboard.${this.type}.delete_configuration_from_folder_modal_text`, { name: configuration.name });
        }

        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                this.aiConfigService.removeConfigurationFromFolder(this.folder, configuration);

                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer ${this.trackType} du dossier`,
                    track_cible: 'supprimer',
                });
            } else {
                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer ${this.trackType} du dossier`,
                    track_cible: 'annuler',
                });
            }
        });
    }

    get trackType() {
        return this.type === FolderType.IaConfig ? 'configuration IA' : 'thématique';
    }

    edit(configuration) {
        if (this.aiConfigService.type === FolderType.Thematic) {
            const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
            modal.componentInstance.mode = ThematicEditorMode.UPDATE;
            modal.componentInstance.gtmTrackName = 'modifier thématique';
            modal.componentInstance.initValue = {
                name: configuration.name,
                definition: JSON.parse(JSON.stringify([configuration.description])),
                lang: configuration.lang,
            };

            modal.componentInstance.save.subscribe((thematic: Thematic) => {
                modal.close();

                const langParams = { name: thematic.name, definition: thematic.definition };
                const params = { themId: configuration.id, lang: langParams };
                this.thematicService.patchV1ThematicThemId(params).subscribe(() => {
                    configuration.name = thematic.name;
                    // eslint-disable-next-line prefer-destructuring
                    configuration.description = thematic.definition[0];

                    if (configuration.nb_dashboards > 0) {
                        const infoModal = this.modalService.open(ModalComponent, {});
                        infoModal.componentInstance.titleToTranslate = 'translations.analysisDashboard.thematic.thematic_updated_popup_title';
                        infoModal.componentInstance.contentToTranslate = 'translations.analysisDashboard.thematic.thematic_updated_popup_message';
                        infoModal.componentInstance.btnCloseVariant = 'primary';
                    }
                });
            });
        } else {
            const modal = this.modalService.open(AiconfigEditModalComponent, {});
            modal.componentInstance.configuration = configuration;
            modal.componentInstance.aiconfigService = this.aiConfigService;
        }
    }

    async toggleFavori(row: any): Promise<void> {
        const index = this.configurations.findIndex((config) => config.id === row.id);

        if (row.is_favori) {
            await firstValueFrom(this.corpusApi.unsetFavorite(this.aiConfigService.type, row.id));
        } else {
            await firstValueFrom(this.corpusApi.setFavorite(this.aiConfigService.type, row.id));
        }
        this.configurations[index].is_favorite = !row.is_favorite;
    }

    selectOneConfiguration(configuration) {
        this.aiConfigService.selectedConfigurations.next([configuration]);
    }
}
