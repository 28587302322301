import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import {
    ChatMode, Conversation, ConversationStatus, Role,
} from 'app/api/models/conversation';
import { CorpusService as CorpusApi, DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { firstValueFrom } from 'rxjs';
import MarkdownIt from 'markdown-it';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { ExplorationLLM } from 'app/api/models/llm';
import { Store } from '@ngxs/store';
import { SetIsDiscoveryInProgress } from 'app/stores/actions/settings/settings.actions';
import { TranslateService } from '@ngx-translate/core';
import { FolderType } from 'app/api/models/folder';
import { DEFAULT_MODEL_ID, DEFAULT_TEMPERATURE } from '../chat-dinootoo.component';

@Component({
    selector: 'app-chat-dinootoo-discovery',
    templateUrl: './chat-dinootoo-discovery.component.html',
    styleUrls: ['./chat-dinootoo-discovery.component.scss'],
})
export default class ChatDinootooDiscoveryComponent extends BaseComponent implements OnInit {
    public currentDashboardId: number;

    public md: MarkdownIt;

    public currentDiscovery: Conversation;

    public showErrorValidSummary = false;

    public showErrorSaveDiscoveryName = false;

    public showFinishMessage = false;

    public nbVerbatimOfDiscovery;

    public nbMaxVerbatim: number;

    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    get isDiscoveryMode() {
        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY;
    }

    get isDashInitial() {
        return this.manageDashboardService.currentDashboard?.getValue()?.dash_initial || false;
    }

    get discoverySummary() {
        return this.chatService.interview.getValue().messages.find((m) => m.role === Role.SUMMARY);
    }

    get discoveryName() {
        return this.chatService.interview.getValue().messages.find((m) => m.role === Role.DISCOVERY_NAME);
    }

    constructor(
        private store: Store,
        private dashboardApi: DashboardApi,
        public chatService: ChatService,
        public manageDashboardService: ManageDashboardService,
        private corpusApi: CorpusApi,
        public translate: TranslateService,
    ) {
        super();

        // Init MarkdownIt
        this.md = new MarkdownIt({
            html: false, // prevent html in markdown to prevent XSS
        });
        this.md.disable('image');
    }

    ngOnInit(): void {
        this.nbMaxVerbatim = this.store.snapshot().settings.vcrm.llm_limits['discovery-nb-max-verbatim'];

        // Lorsque la configuration change, on met à jour la configuration courante
        this.subs.sink = this.chatService.interview.subscribe((interview) => {
            this.currentDiscovery = JSON.parse(JSON.stringify(interview));
        });

        // Lorsque l'on change le mode du chat et que l'on a sélectionné "Découverte"
        this.subs.sink = this.chatService.chatMode.subscribe((mode) => {
            if (mode === ChatMode.DISCOVERY && !this.chatService.interview.getValue()) {
                this.startNewDiscovery();
            }
        });

        // Lorsque l'on clique sur le bouton "Nouvelle conversation" (du header)
        this.subs.sink = this.chatService.startNewChatDinootoo.subscribe(() => {
            if (this.isDiscoveryMode) {
                this.startNewDiscovery();
            }
        });

        // Lorsque l'on envoie un message
        this.subs.sink = this.chatService.sendQuestionChatDinootoo.subscribe((question) => {
            if (question && this.isDiscoveryMode) {
                this.onSendQuestion(question);
            }
        });

        // Lorsque l'on créé une nouvelle découverte (fin du processus)
        this.subs.sink = this.chatService.launchDiscovery.subscribe((discovery: ExplorationLLM) => {
            this.showFinishMessage = true;
            if (discovery) {
                this.nbVerbatimOfDiscovery = discovery.llm_nb_verbatim;
            }
            this.chatService.scrollToBottom.emit();
        });

        // Lorsqu'un dashboard est chargé
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            // si le chat est déjà ouvert et que l'on charge un dashboard
            if (this.chatService.isChatDisplay) {
                // Si on passe sur un dashboard de comparaison, on bascule sur le chat conversation (la découverte n'est pas disponible en comparaison)
                if (this.manageDashboardService.isDashboardComparison) {
                    this.chatService.chatMode.next(ChatMode.CONVERSATION);
                } else {
                    // sinon on démarre une nouvelle découverte
                    this.startNewDiscovery();
                }
            }
        });

        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(async () => {
            this.manageFilters();
        });
    }

    /**
     * Efface les anciens messages et démarre une nouvelle configuration
     */
    public async startNewDiscovery() {
        this.chatService.isLoadingAnswer = false;
        this.chatService.showValidDiscoveryBtn = false;
        this.chatService.showLaunchDiscoveryBtn = !!this.chatService.useExistingConfiguration;
        this.store.dispatch(new SetIsDiscoveryInProgress(false));
        this.showFinishMessage = false;
        this.showErrorValidSummary = false;
        this.showErrorSaveDiscoveryName = false;
        this.nbVerbatimOfDiscovery = undefined;

        // On démarre une nouvelle découverte si on n'est pas sur un dashboard initial
        if (!this.manageDashboardService.currentDashboard?.getValue()?.dash_initial) {
            if (this.chatService.useExistingConfiguration) {
                const discovery = {
                    modelId: DEFAULT_MODEL_ID,
                    temperature: DEFAULT_TEMPERATURE,
                    messages: [{
                        role: Role.EXISTING_CONFIGURATION,
                        content: this.chatService.interviewIaConfig.getValue().description,
                    }],
                    status: ConversationStatus.DIALOG,
                    id: null,
                };
                this.chatService.interview.next(discovery);
            } else {
                // Enregistre l'ID du dashboard sur lequel on lance la conversation
                this.currentDashboardId = this.manageDashboardService.currentDashboard.getValue().dash_id;
                const discovery = {
                    modelId: DEFAULT_MODEL_ID,
                    temperature: DEFAULT_TEMPERATURE,
                    messages: [],
                    status: ConversationStatus.DIALOG,
                    id: null,
                };
                // Démarre une nouvelle configuration
                const interview = await firstValueFrom(this.dashboardApi.createChatbotInterview(
                    this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
                    this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue(),
                ));
                discovery.id = interview.id;
                discovery.messages.push(interview.message);
                this.chatService.interview.next(discovery);

                this.manageFilters();
            }
        }
    }

    get useExistingConfiguration() {
        return this.chatService.useExistingConfiguration;
    }

    /**
     * Gestion des modifications des filtres.
     * Si on modifie les filtres on ajoute un message de warning
     * Si il n'y a plus de modifications, on enlève les messages de warning
     */
    public manageFilters() {
        if (this.chatService.interview.getValue() && this.chatService.interview.getValue().status !== ConversationStatus.ENDED) {
            const interview = JSON.parse(JSON.stringify(this.chatService.interview.getValue()));
            // Si un dashboard est modifié
            if (this.store.snapshot().settings.dashboardsModifications?.some((dashboard) => dashboard.isDashModified)) {
            // On ajoute un message de modification de filtre uniquement si le dernier message n'est pas déjà une modif de filtre
                if (interview.messages[interview.messages.length - 1].role !== Role.NEW_FILTER) {
                    interview.messages.push({
                        role: Role.NEW_FILTER,
                        content: 'translations.analysisDashboard.chatDinootoo.chatbot.discovery.newFilter.content',
                    });

                    this.chatService.interview.next(interview);
                    this.chatService.scrollToBottom.emit();
                }
            } else {
                // Sinon on enlève les messages de warning
                interview.messages = interview.messages.filter((m) => m.role !== Role.NEW_FILTER);
                this.chatService.interview.next(interview);
            }
        }
    }

    /**
     * Evènement lorsque l'utilisateur pose une question
     * Envoi la requête à l'API
     */
    public async onSendQuestion(question: string) {
        gtmClick({ track_category: 'dinootoo', track_name: 'envoyer le message configuration' });
        this.store.dispatch(new SetIsDiscoveryInProgress(true));
        const interview = JSON.parse(JSON.stringify(this.chatService.interview.getValue()));

        interview.messages.push({
            role: Role.USER,
            content: question,
        });

        this.chatService.interview.next(interview);
        this.chatService.scrollToBottom.emit();

        const interviewToSend = JSON.parse(JSON.stringify(interview));
        interviewToSend.messages = interview.messages.filter((m) => [Role.USER, Role.ASSISTANT].includes(m.role));
        interview.messages.push(await this.chatService.sendMessages(this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id, interviewToSend));

        this.chatService.interview.next(interview);
        this.chatService.scrollToBottom.emit();
    }

    /**
     * Evènement lorsque l'utilisateur valide le résumé de la discussion
     */
    public async onValidSummary(newMessage) {
        this.showErrorValidSummary = false;
        this.chatService.isLoadingAnswer = true;

        // modification du message SUMMARY
        const interview = JSON.parse(JSON.stringify(this.chatService.interview.getValue())) as Conversation;
        interview.messages.filter((m) => m.role === Role.SUMMARY || m.role === Role.EXISTING_CONFIGURATION)[0].content = newMessage || this.discoverySummary.content;

        try {
            // On créé la configuration et on récupère le nom par défaut
            const discoveryName = (await firstValueFrom(this.dashboardApi.postChatbotIaConfigDefaultName(
                this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
            ))).default_name;
            // On ajoute le message pour afficher le bloc avec le nom de la configuration
            interview.messages.push({
                role: Role.DISCOVERY_NAME,
                content: discoveryName,
            });
            this.chatService.interview.next(interview);
        } catch (e) {
            this.showErrorValidSummary = true;
        } finally {
            this.chatService.isLoadingAnswer = false;
            this.chatService.scrollToBottom.emit();
        }
    }

    /**
     * Evènement lorsque l'utilisateur enregistre le nom de la configuration
     */
    public async onSaveDiscoveryName(discoveryName: string) {
        if (this.chatService.interview.getValue().id) {
            this.chatService.isLoadingAnswer = true;
            this.showErrorSaveDiscoveryName = false;

            // modification du nom de la configuration
            const interview = JSON.parse(JSON.stringify(this.chatService.interview.getValue())) as Conversation;
            interview.messages.filter((m) => m.role === Role.DISCOVERY_NAME)[0].content = discoveryName;

            try {
                this.chatService.interviewIaConfig.next(
                    await firstValueFrom(this.dashboardApi.postChatbotIaConfig({
                        name: discoveryName,
                        dash_id: this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id,
                        interview_id: interview.id,
                        configuration: this.discoverySummary.content,
                        searchparams: this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue(),
                    })),
                );
                this.store.dispatch(new SetIsDiscoveryInProgress(false));
            } catch (e) {
                this.showErrorSaveDiscoveryName = true;
            } finally {
                this.chatService.interview.next(interview);
                this.chatService.showLaunchDiscoveryBtn = true;
                this.chatService.isLoadingAnswer = false;
                this.chatService.scrollToBottom.emit();
            }
        } else if (this.chatService.useExistingConfiguration) {
            const discoverySummary = this.chatService.interview.getValue().messages.filter((m) => m.role === Role.SUMMARY || m.role === Role.EXISTING_CONFIGURATION)[0].content;
            const savedConfiguration = await firstValueFrom(this.corpusApi.createAiConfiguration(FolderType.IaConfig, discoveryName, discoverySummary));
            this.chatService.interviewIaConfig.next(savedConfiguration);
            this.chatService.showLaunchDiscoveryBtn = true;
        }
    }
}
