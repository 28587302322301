import {
    Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import ChatService from 'app/modules/corpus/corpus-chat.service';

@Component({
    selector: 'app-chat-dinootoo-editable-message',
    templateUrl: './chat-dinootoo-editable-message.component.html',
    styleUrls: ['./chat-dinootoo-editable-message.component.scss'],
})
export default class ChatDinootooEditableMessageComponent {
    @Input()
    public translateStartKey: string;

    @Input()
    public messageContent: string;

    @Input()
    public showAgreeBtn: boolean;

    @Input()
    public disableUpdateBtn: boolean;

    @Input()
    public showErrorValidSummary: boolean;

    @Output()
    public clickBtnEditSummary = new EventEmitter();

    @Output()
    public clickBtnCancelEdit = new EventEmitter();

    @Output()
    public clickBtnValidEdit = new EventEmitter();

    @ViewChild('editedSummary')
        editedSummaryEl: ElementRef;

    public editSummary = false;

    constructor(public chatService: ChatService) {
    }

    public onClickEditSummary() {
        this.editSummary = true;
        this.clickBtnEditSummary.emit();
    }

    public onClickCancelEdit() {
        this.editSummary = false;
        this.clickBtnCancelEdit.emit();
    }

    public onClickValidEdit() {
        this.editSummary = false;
        this.clickBtnValidEdit.emit(this.editedSummaryEl?.nativeElement.innerHTML);
    }
}
