import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
    Thematic, Thematicsdatacount, Thematicslistshare,
} from 'app/api/models';
import { DashboardService as DashboardApi, ThematicService } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ThematicEditorComponent, { ThematicEditorMode } from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import * as _ from 'lodash';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

@Component({
    selector: 'app-filters-thematics',
    templateUrl: './filters-thematics.component.html',
    styleUrls: ['./filters-thematics.component.scss'],
})
export default class FiltersThematicsComponent extends BaseVerticalFilterComponent implements OnInit {
    lang:string = '';

    thematicCounts: Thematicsdatacount;

    get dashboardThematics():any {
        return this.searchParams || { selected_all: false, selected_not: false, thematics: [] };
    }

    allThematics: Thematicslistshare;

    get isAllThematicsSelected():boolean {
        return this.dashboardThematics.thematics.every((thematic) => thematic.selected);
    }

    get isAllIndeterminate():boolean {
        return this.dashboardThematics.thematics.some((thematic) => thematic.selected) && !this.isAllThematicsSelected;
    }

    get personal() {
        return this.dashboardThematics.thematics.filter((thematic) => thematic.type_sharing === '0');
    }

    get shared() {
        return this.dashboardThematics.thematics.filter((thematic) => thematic.type_sharing === '1' || thematic.type_sharing === '2');
    }

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    get count() {
        if (!this.dashboardThematics.selected_all && !this.dashboardThematics.selected_not) {
            return this.dashboardThematics.thematics.reduce((sum, t) => sum + (t.selected ? 1 : 0), 0);
        } if (this.dashboardThematics.selected_not) {
            return this.dashboardThematics.thematics.length;
        }
        return 0;
    }

    constructor(
        private dashboardApi: DashboardApi,
        private dashboardService: DashboardService,
        private modalService: NgbModal,
        private store: Store,
        private thematicService: ThematicService,
        private translateService: TranslateService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService);
    }

    getThematicCount(thematic):string {
        if (!this.thematicCounts) {
            return '';
        }
        const count = this.thematicCounts.thematics_count.find((c) => c.them_id === thematic.them_id);
        return count ? `(${count.count})` : '';
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.lang = this.store.snapshot().corpus.corp_lang.toUpperCase();

        // On met à jour les thématiques lorsque l'on charge un dashboard
        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard !== null && dashboard.dash_json_params.thematics.length > 0) {
                this.collapsed = false;
                this.loadThematicsData();
            }
        });

        // On met à jour les compteurs des thématiques lorsque l'on applique des nouveaux filtres
        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe(() => {
            this.loadThematicsData();
        });

        // On met à jour les thématiques lorsque l'on change la config des thématiques de ce dashboard
        this.subs.sink = this.dashboardService.onThematicsChanged.subscribe(() => {
            this.collapsed = false;
            this.searchParams = _.cloneDeep(this.dashboardService.currentFilters.getValue());
        });

        // On met à jour les thématiques lorsque l'on fusionne des thématiques entre les différents dashboards (de comparaison)
        this.subs.sink = this.manageDashboardService.comparisonMergedThematics.subscribe((service) => {
            this.collapsed = false;

            if (this.dashboardService === service) {
                this.searchParams = _.cloneDeep(service.currentFilters.getValue());
                this.loadThematicsData();
            }
        });
    }

    /**
     * Charge le nombre de données par thématiques
     */
    async loadThematicsData() {
        this.dashboardApi
            .postV1DashboardDashIdThematicsData({
                dashId: this.dashboardService.currentDashboard.getValue().dash_id,
                body: this.dashboardService.currentFilters.getValue(),
            })
            .subscribe((themesDatas) => {
                this.thematicCounts = themesDatas;
            });
    }

    toggleTheme(selectedThematic) {
        // Si une thématique a été cochée
        if (selectedThematic) {
            selectedThematic.selected = !selectedThematic.selected;
        } else {
            // Sinon on toggle toutes les thématiques
            const selected: boolean = !this.isAllThematicsSelected;
            this.dashboardThematics.thematics.forEach((thematic) => {
                thematic.selected = selected;
            });
        }
        this.selectThemes();

        if (this.dashboardThematics.thematics.filter((t) => t.selected).length === 0) {
            this.selectNotTheme();
        }
    }

    selectThemes() {
        this.dashboardThematics.selected_all = false;
        this.dashboardThematics.selected_not = false;

        this.updateThematicList(this.dashboardThematics);
    }

    updateThematicList(thematics) {
        this.dashboardService.updateThematicList(thematics);
    }

    selectAllTheme() {
        this.dashboardThematics.selected_all = true;
        this.dashboardThematics.selected_not = false;

        this.dashboardThematics.thematics.forEach((t) => {
            t.selected = false;
        });
        this.updateThematicList(this.dashboardThematics);
    }

    selectNotTheme() {
        this.dashboardThematics.thematics.forEach((t) => {
            t.selected = false;
        });
        this.dashboardThematics.selected_all = false;
        this.dashboardThematics.selected_not = true;

        this.updateThematicList(this.dashboardThematics);
    }

    openModalEditShared(thematicShared, event) {
        event.target.closest('button').blur();
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'copie thématique partagée',
        });

        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.initValue = {
            name: this.translateService.instant('translations.corpus.filters.thematics.copy_name', { name: thematicShared.them_name }),
            definition: JSON.parse(JSON.stringify(thematicShared.them_definition)),
            lang: thematicShared.them_lang,
        };
        modal.componentInstance.allowSameValue = true;

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();
            thematic.lang = this.lang;

            this.thematicService.postV1Thematic_1(thematic).subscribe((id) => {
                const them = {
                    them_name: thematic.name,
                    them_type: 'rule',
                    them_definition: thematic.definition,
                    lang: this.lang,
                    selected: true,
                    them_id: id.id,
                    perso: true,
                    them_shared: false,
                    type_sharing: '0',
                };
                this.dashboardThematics.selected_all = false;
                this.dashboardThematics.selected_not = false;
                this.dashboardThematics.thematics.push(them);
                this.updateThematicList(this.dashboardThematics);
            });
        });
    }

    openModalEditPerso(thematicPerso, event) {
        event.target.closest('button').blur();
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'modifier thématique',
        });

        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.mode = ThematicEditorMode.UPDATE;
        modal.componentInstance.initValue = {
            name: thematicPerso.them_name,
            definition: JSON.parse(JSON.stringify(thematicPerso.them_definition)),
            lang: thematicPerso.them_lang,
        };

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();

            const langParams = { name: thematic.name, definition: thematic.definition };
            const params = { themId: thematicPerso.them_id, lang: langParams };
            this.thematicService.patchV1ThematicThemId(params).subscribe(() => {
                thematicPerso.selected = true;
                thematicPerso.them_name = thematic.name;
                thematicPerso.them_definition = thematic.definition;
                this.manageDashboardService.renameThematic(thematicPerso);
            });
        });
    }

    /**
     * Supprime une thématique
     */
    delete(thematic) {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'supprimer thématique',
        });
        this.manageDashboardService.deleteThematic(thematic);
    }
}
