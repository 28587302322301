/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import ThematicsModule from 'app/modules/thematics/thematics.module';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import DashPermissionsService from 'app/utils/services/dash-permissions.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { enGbLocale, frLocale } from 'ngx-bootstrap/locale';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import SharedModule from 'app/shared/shared.module';
import AddColumnsService from 'app/utils/services/add-columns.service';
import { NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import NumberSelectorComponent from 'app/shared/components/number-selector/number-selector.component';
import AuthGuardService from '../../utils/services/authguard.service';
import GdprGuardService from '../../utils/services/gdprguard.service';
import NoDataComponent from './components/no-data/no-data.component';
import DashboardNumber from './components/dashboard-number/dashboard-number.component';
import DashboardsSettingsComponent from './components/dashboards-settings/dashboards-settings.component';
import DashboardListComponent from './components/dashboards-settings/dashboard-list/dashboard-list.component';
import NewComparisonModalComponent from './components/dashboards-settings/new-comparison-modal/new-comparison-modal.component';
import SelectDashboardListComponent from './components/dashboards-settings/select-dashboard-list/select-dashboard-list.component';
import SelectDashboardModalComponent from './components/dashboards-settings/select-dashboard-modal/select-dashboard-modal.component';
import DashboardProject from './components/dashboard-project/dashboard-project.component';
import ModalGraphExportComponent from './components/modal-graph-export/modal-graph-export.component';
import ThematicsDropdown from './components/thematics-dropdown/thematics-dropdown.component';
import AssociatedDatasComponent from './corpus-analyses/components/associated-datas/associated-datas.component';
import DataChoiceListComponent from './corpus-analyses/components/dashboard-section/associated-datas-settings/data-choice-list/data-choice-list.component';
import DashboardNameBarComponent from './corpus-analyses/components/dashboard-name-bar/dashboard-name-bar.component';
import GlobalChartsComponent from './corpus-analyses/components/global-charts/global-charts.component';
import NavTabsComponent from './corpus-analyses/components/nav-tabs/nav-tabs.component';
import ThemesListModalComponent from './corpus-analyses/components/themes-list-modal/themes-list-modal.component';
import ToggleAllFiltersComponent from './corpus-analyses/components/toggle-all-filters/toggle-all-filters.component';
import VerbatimCardComponent from './corpus-analyses/components/verbatim-card/verbatim-card.component';
import WordsComponent from './corpus-analyses/components/words/words.component';
import CorpusAnalysesComponent from './corpus-analyses/corpus-analyses.component';
import CorpusDashboardComponent from './corpus-analyses/components/corpus-dashboard.component';
import CorpusRoutingModule from './corpus-routing.module';
import CorpusSettingsComponent from './corpus-settings/corpus-settings.component';
import CorpusSettingsAddFiltersComponent from './corpus-settings/corpus-settings-add-filters/corpus-settings-add-filters.component';
import CorpusSettingsDetailsComponent from './corpus-settings/corpus-settings-details/corpus-settings-details.component';
import CorpusSettingsFilesComponent from './corpus-settings/corpus-settings-files/corpus-settings-files.component';
import CorpusSettingsFiltersComponent from './corpus-settings/corpus-settings-filters/corpus-settings-filters.component';
import CorpusSettingsHistoryComponent from './corpus-settings/corpus-settings-history/corpus-settings-history.component';
import CorpusSettingsUsersComponent from './corpus-settings/corpus-settings-users/corpus-settings-users.component';
import CorpusSettingsVerbatimComponent from './corpus-settings/corpus-settings-verbatim/corpus-settings-verbatim.component';
import CorpusSettingsTabs from './corpus-settings/corpus-settings-tabs/corpus-settings-tabs.component';
import VerbatimExportComponent from './corpus-tones/components/verbatim-export/verbatim-export.component';
import CorpusTonesComponent from './corpus-tones/corpus-tones.component';
import CorpusComponent from './corpus.component';
import FixedValidationBarComponent from './corpus-settings/fixed-validation-bar/fixed-validation-bar.component';
import AlertErrorComponent from './corpus-settings/corpus-settings-files/alert-error/alert-error.component';
import CsvComponent from './corpus-settings/corpus-settings-files/csv/csv.component';
import MsurveyAccountComponent from './corpus-settings/corpus-settings-files/msurvey-account/msurvey-account.component';
import MsurveyUrlComponent from './corpus-settings/corpus-settings-files/msurvey-url/msurvey-url.component';
import ScoreTableComponent from './corpus-settings/corpus-settings-verbatim/score-table/score-table.component';
import WordsTableComponent from './corpus-analyses/components/words/words-table/words-table.component';
import WordsCloudComponent from './corpus-analyses/components/words/words-cloud/words-cloud.component';
import HiddenWordsComponent from './corpus-analyses/components/words/hidden-words/hidden-words.component';
import OneWordComponent from './corpus-analyses/components/words/words-cloud/one-word/one-word.component';
import WordGroupsSelectComponent from './corpus-analyses/components/words/word-groups-select/word-groups-select.component';
import ExtendedCloudComponent from './corpus-analyses/components/words/extended-cloud/extended-cloud.component';
import VerticalFiltersComponent from './components/vertical-filters/vertical-filters.component';
import FiltersPeriodComponent from './components/vertical-filters/components/filters-period/filters-period.component';
import FiltersAssociatedDataComponent from './components/vertical-filters/components/filters-associated-data/filters-associated-data.component';
import FiltersVerbatimComponent from './components/vertical-filters/components/filters-verbatim/filters-verbatim.component';
import FiltersClassificationsAutoComponent from './components/vertical-filters/components/filters-classifications-auto/filters-classifications-auto.component';
import FiltersThematicsComponent from './components/vertical-filters/components/filters-thematics/filters-thematics.component';
import FiltersTitleComponent from './components/vertical-filters/components/common/filters-title/filters-title.component';
import FiltersAssociatedDataPopupComponent from './components/vertical-filters/components/filters-associated-data/components/filters-associated-data-popup/filters-associated-data-popup.component';
import FilterCapsuleComponent from './components/vertical-filters/components/common/filter-capsule/filter-capsule.component';
import FiltersClassificationsAutoPopupSimpleComponent from './components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-simple/filters-classifications-auto-popup-simple.component';
import FiltersClassificationsAutoPopupComplexComponent from './components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-complex/filters-classifications-auto-popup-complex.component';
import FiltersClassificationsAutoPopupHierarchiqueComponent from './components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-hierarchique/filters-classifications-auto-popup-hierarchique.component';
import FiltersToggleComponent from './components/vertical-filters/components/filters-toggle/filters-toggle.component';
import FiltersClassificationsAutoPopupModelInfoComponent from './components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-model-info/filters-classifications-auto-popup-model-info.component';
import FiltersClassificationsAutoPopupModelInfoFieldComponent from './components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-model-info-field/filters-classifications-auto-popup-model-info-field.component';
import FiltersContentEmptyComponent from './components/vertical-filters/components/common/filters-content-empty/filters-content-empty.component';
import FiltersThematicsMenuComponent from './components/vertical-filters/components/filters-thematics/components/filters-thematics-menu/filters-thematics-menu.component';
import FiltersOpinionTargetComponent from './components/vertical-filters/components/filters-opinion-target/filters-opinion-target.component';
import FiltersSearchComponent from './components/vertical-filters/components/filters-search/filters-search.component';
import FiltersSentimentComponent from './components/vertical-filters/components/filters-sentiment/filters-sentiment.component';
import FiltersConversationComponent from './components/vertical-filters/components/filters-conversation/filters-conversation.component';
import FiltersConversationPopupComponent from './components/vertical-filters/components/filters-conversation/components/filters-conversation-popup/filters-conversation-popup.component';
import DashboardMenuBarComponent from './components/dashboard-menu-bar/dashboard-menu-bar.component';
import CorpusAlertsComponent from './components/corpus-alerts/corpus-alerts.component';
import AssociatedDataChronologicalChartSettingsPopupComponent from './corpus-analyses/components/associated-datas/chronological-chart-settings-popup/chronological-chart-settings-popup.component';
import AssociatedDataBarChartSettingsPopupComponent from './corpus-analyses/components/associated-datas/bar-chart-settings-popup/bar-chart-settings-popup.component';
import ExtendedViewPopupComponent from './corpus-analyses/components/associated-datas/extended-view-popup/extended-view-popup.component';
import SatisfactionLineChartComponent from './corpus-analyses/components/satisfaction-charts/components/satisfaction-line-chart/satisfaction-line-chart.component';
import SatisfactionBarChartSettingsPopupComponent from './corpus-analyses/components/satisfaction-charts/components/satisfaction-bar-chart-settings-popup/satisfaction-bar-chart-settings-popup.component';
import SatisfactionBarChartComponent from './corpus-analyses/components/satisfaction-charts/components/satisfaction-bar-chart/satisfaction-bar-chart.component';
import SatisfactionIndicatorComponent from './corpus-analyses/components/satisfaction-charts/components/satisfaction-indicator/satisfaction-indicator.component';
import SatisfactionChartsComponent from './corpus-analyses/components/satisfaction-charts/satisfaction-charts.component';
import SatisfactionLineChartSettingsPopupComponent from './corpus-analyses/components/satisfaction-charts/components/satisfaction-line-chart-settings-popup/satisfaction-line-chart-settings-popup.component';
import DistributionBubbleGraphComponent from './corpus-analyses/components/distribution-graphs/components/distribution-matrix/distribution-bubble-chart/distribution-bubble-chart.component';
import LineGraphComponent from './corpus-analyses/components/distribution-graphs/components/distribution-line-chart/distribution-line-chart.component';
import DistributionTops from './corpus-analyses/components/distribution-graphs/components/distribution-matrix/distribution-tops/distribution-tops.component';
import LineChartSettingsComponent from './corpus-analyses/components/graphs-generic-components/line-chart-settings-popup/line-chart-settings-popup.component';
import GraphLegendComponent from './corpus-analyses/components/graphs-generic-components/graph-legend/graph-legend.component';
import HorizontalBarChartSettingsPopupComponent from './corpus-analyses/components/graphs-generic-components/horizontal-bar-chart-settings-popup/horizontal-bar-chart-settings-popup.component';
import GraphCheckboxLegendComponent from './corpus-analyses/components/graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import DistributionDetailsPopupComponent from './corpus-analyses/components/graphs-generic-components/distribution-details-popup/distribution-details-popup.component';
import DistributionDetailsPopupGraphComponent from './corpus-analyses/components/graphs-generic-components/distribution-details-popup/distribution-details-popup-graph/distribution-details-popup-graph.component';
import DistributionTopKeywordExpressionSettingsPopupComponent from './corpus-analyses/components/distribution-graphs/components/distribution-matrix/distribution-tops/distribution-tops-settings-popup/distribution-tops-settings-popup.component';
import HeatmapComponent from './corpus-analyses/components/heatmap/heatmap.component';
import HeatmapSettingsPopupComponent from './corpus-analyses/components/heatmap/components/heatmap-settings-popup/heatmap-settings-popup.component';
import HeatmapDetailsPopupComponent from './corpus-analyses/components/heatmap/components/heatmap-details-popup/heatmap-details-popup.component';
import VerbatimPaginationComponent from './corpus-analyses/components/verbatim-pagination/verbatim-pagination.component';
import AnnotationsModalComponent from './corpus-analyses/components/verbatim-card/annotations-modal/annotations-modal.component';
import ConversationModalComponent from './corpus-analyses/components/verbatim-card/conversation-modal/conversation-modal.component';
import CopcComponent from './corpus-analyses/components/satisfaction-charts/components/copc/copc.component';
import CopcRadarChartComponent from './corpus-analyses/components/satisfaction-charts/components/copc-radar-chart/copc-radar-chart.component';
import CopcLineChartComponent from './corpus-analyses/components/satisfaction-charts/components/copc-line-chart/copc-line-chart.component';
import CopcLineChartSettingsPopupComponent from './corpus-analyses/components/satisfaction-charts/components/copc-line-chart-settings-popup/copc-line-chart-settings-popup.component';
import AssociatedDatasSettingsComponent from './corpus-analyses/components/dashboard-section/associated-datas-settings/associated-datas-settings.component';
import DistributionBarChartComponent from './corpus-analyses/components/distribution-graphs/components/distribution-bar-chart/distribution-bar-chart.component';
import DistributionGraphsComponent from './corpus-analyses/components/distribution-graphs/distribution-graphs.component';
import ClassificationAutoInfoComponent from './corpus-analyses/components/dashboard-section/classification-auto-info/classification-auto-info.component';
import ComparisonModeSwitchComponent from './corpus-analyses/components/dashboard-section/comparison-mode-switch/comparison-mode-switch.component';
import DashboardSection from './corpus-analyses/components/dashboard-section/dashboard-section.component';
import GraphRadioLegendComponent from './corpus-analyses/components/graphs-generic-components/graph-radio-legend/graph-radio-legend.component';
import DistributionDetailsmergedPopupComponent from './corpus-analyses/components/graphs-generic-components/distribution-details-popup/distribution-details-merged-popup/distribution-details-merged-popup.component';
import { DistributionDetailsPopupPillComponent } from './corpus-analyses/components/graphs-generic-components/distribution-details-popup/distribution-details-popup-pill/distribution-details-popup-pill.component';
import DistributionDetailsPopupBarChartComponent from './corpus-analyses/components/graphs-generic-components/distribution-details-popup/distribution-details-popup-bar-chart/distribution-details-popup-bar-chart.component';
import AssociatedDataLineChartComponent from './corpus-analyses/components/associated-datas/associated-data-line-chart/associated-data-line-chart.component';
import AssociatedDataBarChartComponent from './corpus-analyses/components/associated-datas/associated-data-bar-chart/associated-data-bar-chart.component';
import LabllmComponent from './corpus-analyses/components/labllm/lab-llm.component';
import LlmContextComponent from './corpus-analyses/components/labllm/components/llm-context/llm-context.component';
import LlmCardThemeComponent from './corpus-analyses/components/labllm/components/llm-card-theme/llm-card-theme.component';
import ChatDinootooComponent from './corpus-analyses/components/chat-dinootoo/chat-dinootoo.component';
import ChatDinootooEditableMessageComponent from './corpus-analyses/components/chat-dinootoo/chat-dinootoo-editable-message/chat-dinootoo-editable-message.component';
import ChatDinootooHeader from './corpus-analyses/components/chat-dinootoo/chat-dinootoo-header/chat-dinootoo-header.component';
import ChatDinootooPrompt from './corpus-analyses/components/chat-dinootoo/chat-dinootoo-prompt/chat-dinootoo-prompt.component';
import ChatDinootooConversation from './corpus-analyses/components/chat-dinootoo/chat-dinootoo-conversation/chat-dinootoo-conversation.component';
import ChatDinootooDiscovery from './corpus-analyses/components/chat-dinootoo/chat-dinootoo-discovery/chat-dinootoo-discovery.component';
import ToggleChatDinootooComponent from './corpus-analyses/components/chat-dinootoo/toggle-chat-dinootoo/toggle-chat-dinootoo.component';
import LlmContextBarChartConfigurationPopupComponent from './corpus-analyses/components/labllm/components/llm-context-bar-chart-configuration-popup/llm-context-bar-chart-configuration-popup.component';
import CorpusAiConfigComponent from './corpus-aiconfigs/corpus-aiconfigs.component';
import AiconfigFoldersComponent from './corpus-aiconfigs/components/aiconfig-folders/aiconfig-folders.component';
import AiconfigListComponent from './corpus-aiconfigs/components/aiconfig-list/aiconfig-list.component';
import AiconfigEditFolderNameComponent from './corpus-aiconfigs/components/aiconfig-edit-folder-name/aiconfig-edit-folder-name.component';
import AiconfigEditModalComponent from './corpus-aiconfigs/components/aiconfig-edit-modal/aiconfig-edit-modal.component';
import AiconfigFolderConfigurationsComponent from './corpus-aiconfigs/components/aiconfig-folder-configurations/aiconfig-folder-configurations.component';
import AiconfigShareModalComponent from './corpus-aiconfigs/components/aiconfig-share-modal/aiconfig-share-modal.component';
import LlmChooseConfigurationModalComponent from './corpus-analyses/components/labllm/components/llm-choose-configuration-modal/llm-choose-configuration-modal.component';
import AiconfigMainContentComponent from './corpus-aiconfigs/components/aiconfig-main-content/aiconfig-main-content.component';
import AiconfigConfigurationRowDetailTemplateComponent from './corpus-aiconfigs/components/aiconfig-configuration-row-detail-template/aiconfig-configuration-row-detail-template.component';
import AiconfigChooseConfigurationModalComponent from './corpus-aiconfigs/components/aiconfig-choose-configuration-modal/aiconfig-choose-configuration-modal.component';

defineLocale('fr', frLocale);
defineLocale('en', enGbLocale);

@NgModule({
    imports: [
        SharedModule,
        TooltipModule,
        CollapseModule,
        BsDropdownModule,
        CommonModule,
        CorpusRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        ModalModule,
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AccordionModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgbAlertModule,
        NgbDropdownModule,
    ],
    declarations: [
        CorpusComponent,
        CorpusTonesComponent,
        CorpusAnalysesComponent,
        CorpusDashboardComponent,
        CorpusSettingsComponent,
        AiconfigListComponent,
        AiconfigMainContentComponent,
        AiconfigShareModalComponent,
        AiconfigFoldersComponent,
        AiconfigFolderConfigurationsComponent,
        AiconfigEditModalComponent,
        AiconfigEditFolderNameComponent,
        AiconfigConfigurationRowDetailTemplateComponent,
        AiconfigChooseConfigurationModalComponent,
        CorpusAiConfigComponent,
        CorpusSettingsFilesComponent,
        CorpusSettingsDetailsComponent,
        CorpusSettingsFiltersComponent,
        CorpusSettingsVerbatimComponent,
        CorpusSettingsUsersComponent,
        CorpusSettingsAddFiltersComponent,
        CorpusSettingsTabs,
        VerbatimCardComponent,
        NoDataComponent,
        DashboardNumber,
        DashboardsSettingsComponent,
        NewComparisonModalComponent,
        SelectDashboardListComponent,
        DashboardListComponent,
        SelectDashboardModalComponent,
        ThemesListModalComponent,
        ToggleAllFiltersComponent,
        CorpusSettingsHistoryComponent,
        DashboardProject,
        ModalGraphExportComponent,
        VerbatimExportComponent,
        WordsComponent,
        DashboardNameBarComponent,
        GlobalChartsComponent,
        NavTabsComponent,
        AssociatedDatasComponent,
        HeatmapComponent,
        DataChoiceListComponent,
        FixedValidationBarComponent,
        AlertErrorComponent,
        CsvComponent,
        MsurveyAccountComponent,
        MsurveyUrlComponent,
        WordsTableComponent,
        WordsCloudComponent,
        OneWordComponent,
        HiddenWordsComponent,
        WordGroupsSelectComponent,
        ExtendedCloudComponent,
        ScoreTableComponent,
        VerticalFiltersComponent,
        FiltersPeriodComponent,
        FiltersAssociatedDataComponent,
        FiltersVerbatimComponent,
        FiltersClassificationsAutoComponent,
        FiltersThematicsComponent,
        FiltersTitleComponent,
        FiltersAssociatedDataPopupComponent,
        NumberSelectorComponent,
        FilterCapsuleComponent,
        FiltersClassificationsAutoPopupSimpleComponent,
        FiltersClassificationsAutoPopupComplexComponent,
        FiltersClassificationsAutoPopupHierarchiqueComponent,
        FiltersToggleComponent,
        FiltersClassificationsAutoPopupModelInfoComponent,
        FiltersClassificationsAutoPopupModelInfoFieldComponent,
        FiltersContentEmptyComponent,
        FiltersThematicsMenuComponent,
        FiltersOpinionTargetComponent,
        FiltersSentimentComponent,
        FiltersSearchComponent,
        FiltersConversationComponent,
        FiltersConversationPopupComponent,
        DashboardMenuBarComponent,
        CorpusAlertsComponent,
        ThematicsDropdown,
        AssociatedDataChronologicalChartSettingsPopupComponent,
        AssociatedDataBarChartSettingsPopupComponent,
        ExtendedViewPopupComponent,
        SatisfactionChartsComponent,
        SatisfactionIndicatorComponent,
        SatisfactionBarChartComponent,
        SatisfactionLineChartComponent,
        SatisfactionBarChartSettingsPopupComponent,
        SatisfactionLineChartSettingsPopupComponent,
        DistributionGraphsComponent,
        DistributionBubbleGraphComponent,
        DistributionTops,
        LineGraphComponent,
        LineChartSettingsComponent,
        HorizontalBarChartSettingsPopupComponent,
        GraphLegendComponent,
        GraphCheckboxLegendComponent,
        GraphRadioLegendComponent,
        DistributionDetailsPopupComponent,
        DistributionDetailsmergedPopupComponent,
        DistributionDetailsPopupGraphComponent,
        DistributionTopKeywordExpressionSettingsPopupComponent,
        HeatmapSettingsPopupComponent,
        HeatmapDetailsPopupComponent,
        VerbatimPaginationComponent,
        AnnotationsModalComponent,
        ConversationModalComponent,
        CopcComponent,
        CopcRadarChartComponent,
        CopcLineChartComponent,
        CopcLineChartSettingsPopupComponent,
        AssociatedDatasSettingsComponent,
        DistributionBarChartComponent,
        ClassificationAutoInfoComponent,
        ComparisonModeSwitchComponent,
        DashboardSection,
        DistributionDetailsPopupPillComponent,
        DistributionDetailsPopupBarChartComponent,
        AssociatedDataLineChartComponent,
        AssociatedDataBarChartComponent,
        LabllmComponent,
        LlmContextComponent,
        LlmCardThemeComponent,
        ChatDinootooComponent,
        ChatDinootooHeader,
        ChatDinootooPrompt,
        ChatDinootooConversation,
        ChatDinootooDiscovery,
        ChatDinootooEditableMessageComponent,
        ToggleChatDinootooComponent,
        LlmContextBarChartConfigurationPopupComponent,
        LlmChooseConfigurationModalComponent,
    ],
    providers: [
        AuthGuardService,
        GdprGuardService,
        ChartTooltipsService,
        DashPermissionsService,
        BsModalRef,
        AddColumnsService,
    ],
    exports: [ThematicsModule],
})
export default class CorpusModule {}
