// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .chat-header {
  display: flex;
  height: 50px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background-color: #f16e00;
}
:host .chat-header .chat-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
:host .chat-header .vcrm-info::before,
:host .chat-header .vcrm-settings::before,
:host .chat-header .vcrm-form-minus-default::before {
  font-size: 25px;
}

.discovery-dropdown {
  display: inline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
