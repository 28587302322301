export interface Folder {
    fold_id?:number;
    fold_name?:string;
    fold_type?: FolderType,
    fold_fk_user_id?: number,
    fold_creation_date?:string,
    fold_update_date?;string,
    content?:Array<any>,
    state?: boolean
}

export enum FolderType {
    IaConfig = 'iaconfig',
    Thematic = 'thematic',
}
