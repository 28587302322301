import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Folder, FolderType } from 'app/api/models/folder';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ModalComponent from '../../../../../shared/components/modal/modal.component';

@Component({
    selector: 'aiconfig-folders',
    templateUrl: './aiconfig-folders.component.html',
    styleUrl: './aiconfig-folders.component.scss',
})
export default class AiconfigFoldersComponent extends BaseComponent implements OnInit {
    folders : Array<Folder> = [];

    @Input()
        filter = '';

    @Output()
        foldersChange = new EventEmitter();

    @Output()
        switchToAddMode: EventEmitter<Folder> = new EventEmitter<Folder>();

    @Output()
        folderDeleted: EventEmitter<Folder> = new EventEmitter<Folder>();

    editedFolder = undefined;

    constructor(private modalService: NgbModal, private translateService: TranslateService, private aiconfigService: AiConfigService) {
        super();
    }

    get selectOnlyOne() {
        return this.aiconfigService.selectOnlyOne;
    }

    ngOnInit(): void {
        this.subs.sink = this.aiconfigService.folders.subscribe((folders) => {
            if (folders !== null) {
                this.folders = folders;
            }
        });
    }

    get selection() {
        return this.aiconfigService.selection[0];
    }

    // eslint-disable-next-line class-methods-use-this
    toggleFolder(folder) {
        folder.state = !folder.state;
    }

    addConfigurationsToFolder(folder, fromToolbar: boolean = true) {
        this.aiconfigService.addConfigurationToFolderFromToolbar = fromToolbar;
        this.switchToAddMode.emit(folder);
    }

    getFolderSize(folder: Folder) {
        return this.folders.find((f) => f.fold_id === folder.fold_id).content?.length || 0;
    }

    get type() : FolderType {
        return this.aiconfigService.type;
    }

    get hideActions(): boolean {
        return this.aiconfigService.hideActions;
    }

    get trackType() {
        return this.type === FolderType.IaConfig ? 'configuration IA' : 'thématique';
    }

    async deleteFolder(folder) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = `translations.analysisDashboard.${this.type}.delete_folder_modal_title`;
        modal.componentInstance.content = this.translateService.instant(`translations.analysisDashboard.${this.type}.delete_folder_modal_text`, { name: folder.fold_name });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer dossier ${this.trackType}`,
                    track_cible: 'supprimer',
                });

                this.aiconfigService.deleteFolder(folder);
            } else {
                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer dossier ${this.trackType}`,
                    track_cible: 'annuler',
                });
            }
        });
    }

    async changeFolderName(editedFolder, newName) {
        await this.aiconfigService.renameFolder(editedFolder, newName);
        this.editedFolder = undefined;
    }
}
