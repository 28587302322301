export enum ChatMode {
    CONVERSATION = 'conversation',
    DISCOVERY = 'discovery',
}

export enum Role {
    ASSISTANT = 'assistant',
    USER = 'user',
    NEW_FILTER = 'newFilter',
    SUMMARY = 'summary',
    DISCOVERY_NAME = 'discoveryName',
    EXISTING_CONFIGURATION = 'existing_configuration',
}

export enum ConversationStatus {
    DIALOG = 'dialog',
    ENDED = 'ended',
}

export interface Message {
    role: Role;
    content: string;
    request_summary?: boolean;
    error?: string;
    detail?: any;
}

export interface Conversation {
    id?: string;
    modelId: string;
    temperature: number;
    context?: number;
    prompt?: string;
    messages: Message[];
    status?: ConversationStatus;
}

export interface Model {
    id: string;
    name: string;
}

export interface NbVerbatim {
    id: string;
    nb_verbatim: number;
    nb_tokens?: number;
    nb_conversations?: number;
    metrics_comparison?: { nb_verbatim: number; nb_tokens?: number; }[]
}

export interface OpenAIApiFilterItemError {
    filtered: boolean,
    severity: string
}

export interface OpenAIApiError {
    code: string;
    message: string;
    type: string;
    param: string,
    innererror?: {
        content_filter_result: {
            hate: OpenAIApiFilterItemError;
            self_harm: OpenAIApiFilterItemError;
            sexual: OpenAIApiFilterItemError;
            violence: OpenAIApiFilterItemError;
        }
    }
}

export interface GptError {
    error: OpenAIApiError;
}

export interface Usage {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
}

export interface ConversationResponse {
    answer: string;
    gpt_error: GptError;
    usage: Usage;
    validConfig: number;
}

export enum OpenAIErrorCode {
    CONTENT_FILTER = 'content_filter',
    CONTEXT_LENGTH = 'context_length_exceeded',
}

// Different from OpenAI Errors because we will have more than one AI provider
export enum PromptError {
    CONTEXT_LENGTH = 'context_length_exceeded',
    CONTENT_FILTER = 'content_filter',
    CONTENT_FILTER_SEXUAL = 'content_filter_sexual',
    CONTENT_FILTER_SELFHARM = 'content_filter_selfharm',
    CONTENT_FILTER_HATE = 'content_filter_hate',
    CONTENT_FILTER_VIOLENCE = 'content_filter_violence',
    UNKNOWN = 'unknown',
    API_ERROR = 'api_error',
}
