import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
    ChatMode, ConversationStatus, Model, Role,
} from 'app/api/models/conversation';
import { BaseComponent } from 'app/base.component';
import AiconfigChooseConfigurationModalComponent from 'app/modules/corpus/corpus-aiconfigs/components/aiconfig-choose-configuration-modal/aiconfig-choose-configuration-modal.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import { FolderType } from 'app/api/models/folder';
import { MODELS } from '../chat-dinootoo.component';

@Component({
    selector: 'app-chat-dinootoo-header',
    templateUrl: './chat-dinootoo-header.component.html',
    styleUrls: ['./chat-dinootoo-header.component.scss'],
})
export default class ChatDinootooHeaderComponent extends BaseComponent implements OnInit {
    public modal: NgbModalRef;

    @ViewChild('confirmNewModal')
        confirmNewModal: TemplateRef<any>;

    @ViewChild('notFinishModal')
        notFinishModal: TemplateRef<any>;

    public systemPrompt : string;

    // Retourne l'url de la page d'aide en fonction de la langue
    get helpPageUrl() {
        if (this.manageDashboardService.isDashboardComparison) {
            if (this.translate.currentLang === 'en') {
                return '/help-en/books/analysis-dashboard/chapter/dashboard-comparison';
            }
            return '/help/books/dashboard-analyses/chapter/comparaison-de-dashboards';
        }
        if (!this.isConversationMode) {
            if (this.translate.currentLang === 'en') {
                return '/help-en/books/decouverte-ia-chat-pour-configurer-la-decouverte-automatique-de-themes/page/decouverte-automatique-des-themes';
            }
            return '/help/books/decouverte-ia-chat-pour-configurer-la-decouverte-automatique-de-themes/page/decouverte-automatique-des-themes';
        }
        if (this.translate.currentLang === 'en') {
            return 'help-en/books/dinootoo-in-visual-crm';
        }
        return 'help/books/dinootoo-dans-visual-crm';
    }

    get isConversationMode() {
        return this.chatService.chatMode.getValue() === ChatMode.CONVERSATION;
    }

    get conversation() {
        return this.chatService.conversation.getValue();
    }

    get mode() {
        return this.chatService.chatMode.getValue();
    }

    get discoverySummary() {
        return this.chatService.interview.getValue().messages.find((m) => m.role === Role.SUMMARY)?.content;
    }

    constructor(
        private store: Store,
        public modalService: NgbModal,
        private translate: TranslateService,
        public chatService: ChatService,
        private manageDashboardService: ManageDashboardService,
        private corpusService: CorpusService,
    ) {
        super();
    }

    ngOnInit(): void {
        // Lorsque le dashboard est chargé
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            if (this.corpusService.isCorpusConversation()) {
                this.systemPrompt = this.store.snapshot().settings.vcrm.llm_prompt['dinootoo-conversation'][this.translate.currentLang];
            } else if (this.manageDashboardService.isDashboardComparison) {
                this.systemPrompt = this.store.snapshot().settings.vcrm.llm_prompt['dinootoo-comparison'][this.translate.currentLang];
            } else {
                this.systemPrompt = this.store.snapshot().settings.vcrm.llm_prompt.dinootoo[this.translate.currentLang];
            }
        });
    }

    /**
     * Retourne l'objet model en fonction de l'ID demandé
     * @param modelId : id du model recherché
     */
    // eslint-disable-next-line class-methods-use-this
    public getModelById(modelId: string): Model {
        return MODELS.find((model) => model.id === modelId);
    }

    public onNewConversation() {
        this.modal = this.modalService.open(this.confirmNewModal);
        this.modal.closed.subscribe((value) => {
            if (value !== undefined) {
                if (this.manageDashboardService.isDashboardComparison) {
                    gtmClick({
                        track_category: 'dashboard comparaison',
                        track_name: 'dinootoo db comparaison nouvelle conversation',
                        track_cible: value ? 'valider' : 'annuler',
                    });
                } else {
                    gtmClick({
                        track_category: 'dinootoo',
                        track_name: 'nouvelle conversation',
                        track_cible: value ? 'valider' : 'annuler',
                    });
                }

                if (value) {
                    this.onConfirmNew();
                }
            }
        });
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Nouvelle configuration \ Créer une nouvelle configuration"
     */
    public onNewConfig() {
        // Si on n'a pas commencé la configuration ou que la configuration est complètement terminée et lancée
        if (this.chatService.interview.getValue().messages.length === 1 || this.chatService.interview.getValue().status === ConversationStatus.ENDED) {
            this.chatService.useExistingConfiguration = false;
            this.chatService.startNewChatDinootoo.emit();
        } else if (this.store.snapshot().settings.isDiscoveryInProgress) {
            // Si la conversation est en cours (on est encore en discussion avec l'IA) et le résumé n'est pas encore validé, on affiche la modale de confirmation
            this.modal = this.modalService.open(this.confirmNewModal);
            this.modal.closed.subscribe((value) => {
                if (value !== undefined) {
                    gtmClick({
                        track_category: 'dinootoo',
                        track_name: 'supprimer configuration en cours',
                        track_cible: value ? 'valider' : 'annuler',
                    });
                    if (value) {
                        this.onConfirmNew();
                    }
                }
            });
        } else {
            // Sinon on a fini de discuter avec l'IA et que l'on a validé le résumé, mais que la configuration n'est pas encore lancé, on affiche une popin pour confirmer
            this.modal = this.modalService.open(this.notFinishModal);
        }
    }

    get dicoveryDisabled() {
        return this.manageDashboardService.currentDashboard?.getValue().dash_initial || false;
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Nouvelle configuration \ Utiliser une configuration existante"
     */
    async onOpenConfigurationModal() {
        // Si on n'a pas commencé la configuration ou que la configuration est complètement terminée et lancée
        if (this.chatService.interview.getValue().messages.length === 1 || this.chatService.interview.getValue().status === ConversationStatus.ENDED) {
            this.chooseConfiguration();
        } else if (this.store.snapshot().settings.isDiscoveryInProgress) {
        // Si la conversation est en cours (on est encore en discussion avec l'IA) et le résumé n'est pas encore validé, on affiche la modale de confirmation
            this.modal = this.modalService.open(this.confirmNewModal);

            this.modal.closed.subscribe((value) => {
                gtmClick({
                    track_category: 'dinootoo',
                    track_name: 'modifier la configuration existante',
                    track_cible: value ? 'valider' : 'annuler',
                });

                if (value) {
                    this.chooseConfiguration();
                }
            });
        }
    }

    chooseConfiguration() {
        const modal = this.modalService.open(AiconfigChooseConfigurationModalComponent, { size: '700' });
        modal.componentInstance.type = FolderType.IaConfig;
        modal.componentInstance.selected.subscribe(async (configurations) => {
            this.chatService.isChatDisplay = true;
            this.chatService.useExistingConfiguration = true;
            this.chatService.showLaunchDiscoveryBtn = true;
            this.chatService.interviewIaConfig.next(configurations[0]);
            this.chatService.chatMode.next(ChatMode.DISCOVERY);
            this.chatService.startNewChatDinootoo.emit();
        });
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Valider" des modales "Nouvelle conversation" ou "Nouvelle configuration"
     */
    public async onConfirmNew() {
        this.chatService.useExistingConfiguration = false;
        this.chatService.startNewChatDinootoo.emit();
        this.modal.close();
    }

    /**
     * Evènement lorsque l'on ferme le chat
     */
    public onCloseChat() {
        if (this.manageDashboardService.isDashboardComparison) {
            gtmClick({ track_category: 'dashboard comparaison', track_name: 'dinootoo db comparaison réduire la conversation' });
        } else {
            gtmClick({ track_category: 'dinootoo', track_name: `réduire la ${this.isConversationMode ? 'conversation' : 'configuration'}` });
        }
        this.chatService.isChatDisplay = false;
    }
}
