import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CorpusService as CorpusApi, ThematicService } from 'app/api/services';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import accentless from 'app/utils/functions/accentless';
import { firstValueFrom } from 'rxjs';
import ThematicEditorComponent, { ThematicEditorMode } from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { Thematic } from 'app/api/models';
import { FolderType } from 'app/api/models/folder';
import AiconfigEditModalComponent from '../aiconfig-edit-modal/aiconfig-edit-modal.component';
import ConfigurationRow from '../configuration-row';

@Component({
    selector: 'aiconfig-list',
    templateUrl: './aiconfig-list.component.html',
    styleUrls: ['./aiconfig-list.component.scss'],
})
export default class AiconfigListComponent extends BaseComponent implements OnChanges, OnInit {
    filteredConfigurations: Array<ConfigurationRow>;

    configurations: Array<IaConfigResponse> = [];

    @Input()
        filter = '';

    @Input()
    public listCollapse: boolean = false;

    @Input()
    public scrollable = true;

    @Input() type: string = '';

    @Output()
    public listCollapseChange = new EventEmitter<boolean>();

    count = 0;

    @Input() admin: boolean = false;

    @ViewChild('actionsRowTpl') actionsRowTpl: TemplateRef<any>;

    @ViewChild('selectRowTpl') selectRowTpl: TemplateRef<any>;

    @ViewChild('headerSelectTpl') headerSelectTpl: TemplateRef<any>;

    @ViewChild('favRowTpl') favRowTpl: TemplateRef<any>;

    @ViewChild('favColTpl') favColTpl: TemplateRef<any>;

    @ViewChild('sharedRowTpl') sharedRowTpl: TemplateRef<any>;

    @ViewChild('detailRowTpl') detailRowTpl: TemplateRef<any>;

    @ViewChild('selectOneRowTpl') selectOneRowTpl: TemplateRef<any>;

    @ViewChild('originRowTpl') originRowTpl: TemplateRef<any>;

    constructor(
        private modalService: NgbModal,
        private aiconfigService: AiConfigService,
        private translateService: TranslateService,
        private corpusApi: CorpusApi,
        private thematicService: ThematicService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.aiconfigService.configurations.subscribe((allConfigurations) => {
            if (allConfigurations !== null) {
                if (this.type === 'shared') {
                    this.configurations = [
                        ...(allConfigurations.shared.map((configuration) => {
                            configuration.origin = 'shared';
                            return configuration;
                        }) || []),
                        ...(allConfigurations.sharedInDashboard.map((configuration) => {
                            configuration.origin = 'sharedInDashboard';
                            return configuration;
                        }) || []),
                    ];
                } else {
                    this.configurations = allConfigurations[this.type] || [];
                }
                this.applyFilter();

                this.initColumns();
                this.count = this.getCount();
            }
        });

        this.subs.sink = this.aiconfigService.selectionStateChange.subscribe(() => {
            this.initColumns();
        });

        this.subs.sink = this.aiconfigService.closeAllConfigurationDetails.subscribe(() => {
            this.filteredConfigurations.forEach((row: ConfigurationRow) => {
                row.isCollapsed = true;
            });
        });
    }

    get countEnabled() {
        return this.aiconfigService.countEnabled;
    }

    get trackType() {
        return this.entityType === FolderType.IaConfig ? 'configuration IA' : 'thématique';
    }

    // eslint-disable-next-line class-methods-use-this
    public get config(): any {
        return {
            hasDetails: true,
            detailTemplate: this.detailRowTpl,
            collapseAllDetailsTooltipToTranslate: 'translations.analysisDashboard.themes.details_tooltip',
            style: {
                maxHeight: this.scrollable ? '400px' : 'unset',
                'overflow-y': this.scrollable ? 'scroll' : 'unset',
            },
        };
    }

    get selection() {
        return this.aiconfigService.selection[1] || this.aiconfigService.selection[2];
    }

    public columns: Array<any> = [];

    getCount() {
        return this.configurations.filter((configuration: any) => configuration.checked).length;
    }

    get favo() {
        return {
            prop: 'is_favorite',
            cellTemplate: this.favRowTpl,
            headerTemplate: this.favColTpl,
            title: 'F',
            sort: true,
            style: {
                width: '50px',
            },
        };
    }

    private name = {
        prop: 'name',
        title: 'translations.utils.name',
        sort: true,
        wordWrap: true,
        style: {
            'word-break': 'break-word',
        },
    };

    private update = {
        prop: 'update_date',
        title: 'translations.analysisDashboard.themes.last_update',
        sort: true,
        style: {
            width: '150px',
        },
    };

    get owner() {
        return {
            prop: 'usernameOwner',
            title: `translations.analysisDashboard.${this.entityType}.owner`,
            sort: true,
            style: {
                width: '200px',
            },
        };
    }

    get sharingDate() {
        return {
            prop: 'sharing_date',
            title: `translations.analysisDashboard.${this.entityType}.share_date`,
            sort: true,
            style: {
                width: '150px',
            },
        };
    }

    get actions() {
        return {
            title: '',
            cellTemplate: this.actionsRowTpl,
            style: {
                width: '70px',
            },
        };
    }

    get origin() {
        return {
            prop: 'origin',
            title: `translations.analysisDashboard.${this.entityType}.origin`,
            cellTemplate: this.originRowTpl,
            sort: true,
            style: {
                width: '150px',
            },
        };
    }

    private lang = {
        prop: 'lang',
        title: 'translations.thematics.language',
        sort: true,
        style: {
            width: '150px',
        },
    };

    get entityType() : FolderType {
        return this.aiconfigService.type;
    }

    get selectionCol() {
        return {
            headerTemplate: this.headerSelectTpl,
            cellTemplate: this.selectRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    get selectOnlyOne() {
        return this.aiconfigService.selectOnlyOne;
    }

    get perso(): boolean {
        return this.type === 'personal';
    }

    isSelected(configuration) {
        return this.aiconfigService.selectedConfigurations.getValue().indexOf(configuration) >= 0;
    }

    get selectedCount() {
        // eslint-disable-next-line max-len
        return this.aiconfigService.selectedConfigurations.getValue().filter((configuration) => ((configuration.type_sharing === '0' && this.perso) || (!this.perso && configuration.type_sharing !== '0'))).length;
    }

    isAllSelected() {
        return this.filteredConfigurations.every((row:ConfigurationRow) => this.aiconfigService.selectedConfigurations.getValue().indexOf(row.content) >= 0);
    }

    getTitle() {
        return this.translateService.instant(`translations.analysisDashboard.${this.aiconfigService.type}.${this.type}`);
    }

    isDisabled(configuration) {
        return this.aiconfigService.shareConfiguration && !configuration.perso;
    }

    isAllDisabled() {
        return this.aiconfigService.shareConfiguration && !this.perso;
    }

    async toggleFavori(row: any): Promise<void> {
        const index = this.configurations.findIndex((config) => config.id === row.id);
        if (row.is_favori) {
            await firstValueFrom(this.corpusApi.unsetFavorite(this.aiconfigService.type, row.id));
        } else {
            await firstValueFrom(this.corpusApi.setFavorite(this.aiconfigService.type, row.id));
        }
        this.configurations[index].is_favorite = !row.is_favorite;
        this.configurations = [...this.configurations];
    }

    toggleAll(value) {
        const selectedConfigurations = [...this.aiconfigService.selectedConfigurations.getValue()];
        this.filteredConfigurations.forEach((row) => {
            const index = selectedConfigurations.indexOf(row.content);
            if (value && index < 0) {
                selectedConfigurations.push(row.content);
            } else if (!value && index >= 0) {
                selectedConfigurations.splice(index, 1);
            }
        });
        this.aiconfigService.selectedConfigurations.next(selectedConfigurations);
    }

    toggleSelection(configuration) {
        const selectedConfigurations = [...this.aiconfigService.selectedConfigurations.getValue()];
        const index = selectedConfigurations.indexOf(configuration);
        if (index > -1) {
            selectedConfigurations.splice(index, 1);
        } else {
            selectedConfigurations.push(configuration);
        }

        this.aiconfigService.selectedConfigurations.next(selectedConfigurations);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.configurations || changes.filter) {
            this.applyFilter();
        }

        if (changes.selection) {
            this.initColumns();
        }
    }

    applyFilter() {
        const findR = new RegExp(accentless(this.filter), 'gimu');

        this.filteredConfigurations = [];
        this.configurations.forEach((configuration) => {
            findR.lastIndex = 0;
            if (findR.test(accentless(configuration.search))) {
                this.filteredConfigurations.push(new ConfigurationRow(configuration));
            }
        });
    }

    get shared() {
        return {
            title: `translations.analysisDashboard.${this.aiconfigService.type}.shared_column`,
            cellTemplate: this.sharedRowTpl,
            prop: 'shared',
            sort: true,
            style: {
                width: '50px',
            },
        };
    }

    get persoColumns() {
        return this.aiconfigService.getPersoColumns().map((columnName) => this[columnName]);
    }

    get sharedColumns() {
        return this.aiconfigService.getSharedColumns().map((columnName) => this[columnName]);
    }

    initColumns() {
        this.columns = [];

        this.columns.push(this.favo);
        if (this.selectOnlyOne) {
            this.columns.push(this.selectionOneCol);
        }

        if (this.selection) {
            this.columns.push(this.selectionCol);
        }

        if (this.perso) {
            this.columns = [...this.columns, ...this.persoColumns];
        } else {
            this.columns = [...this.columns, ...this.sharedColumns];
        }

        if (!this.aiconfigService.hideActions) {
            this.columns.push(this.actions);
        }
    }

    edit(configuration) {
        if (this.aiconfigService.type === FolderType.Thematic) {
            const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
            modal.componentInstance.gtmTrackName = 'modifier thématique';
            modal.componentInstance.mode = ThematicEditorMode.UPDATE;
            modal.componentInstance.initValue = {
                name: configuration.name,
                definition: JSON.parse(JSON.stringify([configuration.description])),
                lang: configuration.lang,
            };

            modal.componentInstance.save.subscribe((thematic: Thematic) => {
                modal.close();

                const langParams = { name: thematic.name, definition: thematic.definition };
                const params = { themId: configuration.id, lang: langParams };
                this.thematicService.patchV1ThematicThemId(params).subscribe(() => {
                    configuration.name = thematic.name;
                    // eslint-disable-next-line prefer-destructuring
                    configuration.description = thematic.definition[0];
                    if (configuration.nb_dashboards > 0) {
                        const infoModal = this.modalService.open(ModalComponent, {});
                        infoModal.componentInstance.titleToTranslate = 'translations.analysisDashboard.thematic.thematic_updated_popup_title';
                        infoModal.componentInstance.contentToTranslate = 'translations.analysisDashboard.thematic.thematic_updated_popup_message';
                        infoModal.componentInstance.btnCloseVariant = 'primary';
                    }
                });
            });
        } else {
            const modal = this.modalService.open(AiconfigEditModalComponent, {});
            modal.componentInstance.configuration = configuration;
            modal.componentInstance.aiconfigService = this.aiconfigService;
        }
    }

    delete(configuration: IaConfigResponse) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = `translations.analysisDashboard.${this.aiconfigService.type}.deleteModalTitle`;
        modal.componentInstance.content = this.translateService.instant(`translations.analysisDashboard.${this.aiconfigService.type}.deleteModalMessage`, { name: configuration.name });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer ${this.trackType}`,
                    track_cible: 'supprimer',
                });
                this.aiconfigService.deleteConfiguration(configuration);
            } else {
                gtmClick({
                    track_category: `gestion ${this.trackType}`,
                    track_name: `supprimer ${this.trackType}`,
                    track_cible: 'annuler',
                });
            }
        });
    }

    toggleCollapse() {
        this.listCollapse = !this.listCollapse;
        this.listCollapseChange.emit(this.listCollapse);
    }

    get selectionOneCol() {
        return {
            title: '',
            cellTemplate: this.selectOneRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    selectOneConfiguration(configuration) {
        this.aiconfigService.selectedConfigurations.next([configuration]);
    }

    copy(configuration) {
        this.aiconfigService.copy(configuration);
    }
}
