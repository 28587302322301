import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';

@Component({
    selector: 'aiconfig-edit-folder-name',
    templateUrl: './aiconfig-edit-folder-name.component.html',
    styleUrl: './aiconfig-edit-folder-name.component.scss',
})
/**
 * Petit composant qui permet de d'éditer le nom d'un dossier (création ou renmmage)
 */
export default class AiconfigEditFolderNameComponent implements OnInit {
    constructor(private aiconfigService: AiConfigService) {

    }

    @Input()
        appGtm_validate = {};

    @Input()
        appGtm_cancel = {};

    @Input()
        folderName = '';

    initialFolderName :string = '';

    @Output()
        folderNameChange = new EventEmitter<string>();

    @Output()
        cancel = new EventEmitter();

    ngOnInit(): void {
        this.initialFolderName = this.folderName;
    }

    get isDuplicate() {
        return this.initialFolderName !== this.folderName && this.aiconfigService.folders.getValue().some((folder) => folder.fold_name === this.folderName);
    }

    get type() {
        return this.aiconfigService.type;
    }
}
