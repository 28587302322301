import {
    Component, EventEmitter, Input, OnInit,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FolderType } from 'app/api/models/folder';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import { gtmClick } from 'app/shared/directives/gtm.directive';

@Component({
    selector: 'aiconfig-choose-configuration-modal',
    templateUrl: './aiconfig-choose-configuration-modal.component.html',
    styleUrl: './aiconfig-choose-configuration-modal.component.scss',
    providers: [AiConfigService],
})
/**
 * Popup de choix d'une configuration.
 */
export default class AiconfigChooseConfigurationModalComponent implements OnInit {
    constructor(private aiconfigService: AiConfigService, public modal: NgbActiveModal, private modalService: NgbModal) {}

    @Input()
        defaultConfigurations: Array<number> = [];

    selectedConfigurations: Array<IaConfigResponse> = [];

    selected: EventEmitter<Array<IaConfigResponse>> = new EventEmitter<Array<IaConfigResponse>>();

    @Input()
        type: FolderType;

    @Input()
        lang: string = undefined;

    @Input()
        selectOnlyOne: boolean = true;

    @Input()
        appGtm_validate;

    @Input()
        appGtm_cancel;

    async ngOnInit() {
        this.aiconfigService.hideActions = true;
        this.aiconfigService.selectedConfigurations.subscribe((values) => {
            if (values === null || values.length === 0) {
                this.selectedConfigurations = [];
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.selectedConfigurations = values;
            }
        });

        this.aiconfigService.selectOnlyOne = this.selectOnlyOne;
        this.aiconfigService.countEnabled = this.type === FolderType.Thematic && !this.selectOnlyOne;

        if (!this.selectOnlyOne) {
            this.aiconfigService.changeSelectionState(true, true, true);
        }

        this.aiconfigService.load(this.type, this.defaultConfigurations, this.lang);
    }

    close() {
        if (this.appGtm_cancel) {
            gtmClick(this.appGtm_cancel);
        }
        this.modal.close();
    }

    validate() {
        if (this.appGtm_validate) {
            gtmClick(this.appGtm_validate);
        }
        this.selected.emit(this.selectedConfigurations);
        this.modal.close();
    }
}
