import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ThematicService } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ThematicEditorComponent from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import { FolderType } from 'app/api/models/folder';
import AiconfigChooseConfigurationModalComponent from '../../corpus-aiconfigs/components/aiconfig-choose-configuration-modal/aiconfig-choose-configuration-modal.component';
import ManageDashboardService from '../../corpus-manage-dashboard.service';

@Component({
    selector: 'app-thematics-dropdown',
    templateUrl: './thematics-dropdown.component.html',
    styleUrls: ['./thematics-dropdown.component.scss'],
})
export default class ThematicsDropdownComponent implements OnInit {
    @Input()
        selectedThematics = { selected_all: false, selected_not: false, thematics: [] };

    @Input()
        btnToggleClass: string = 'btn btn-icon';

    @Input()
        gtmTrackCategory: string = '';

    @Input()
        dashboardService: DashboardService;

    lang:string = '';

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private thematicService: ThematicService,
        private corpusService: CorpusService,
        private manageDashboardService: ManageDashboardService,
    ) {
    }

    ngOnInit(): void {
        this.lang = this.store.snapshot().corpus.corp_lang.toUpperCase();
    }

    onSettings(create:boolean) {
        if (this.gtmTrackCategory) {
            gtmClick({
                track_category: this.gtmTrackCategory,
                track_name: `${create ? 'création' : 'sélection'} thématique`,
            });
        }
        if (create) {
            this.openCreateThematicModal();
        } else {
            this.openSelectThematicsModal();
        }
    }

    openSelectThematicsModal() {
        const modal = this.modalService.open(AiconfigChooseConfigurationModalComponent, { size: '700' });
        modal.componentInstance.lang = this.corpusService.currentCorpus.getValue().corp_lang;
        modal.componentInstance.type = FolderType.Thematic;
        modal.componentInstance.selectOnlyOne = false;
        modal.componentInstance.defaultConfigurations = this.selectedThematics.thematics.map((t) => t.them_id);

        modal.componentInstance.appGtm_validate = {
            track_category: 'filtrage sur verbatim',
            track_name: 'validation sélection thématique',
            track_cible: 'valider',
        };

        modal.componentInstance.appGtm_cancel = {
            track_category: 'filtrage sur verbatim',
            track_name: 'validation sélection thématique',
            track_cible: 'annuler',
        };

        modal.componentInstance.selected.subscribe(async (configurations) => {
            const thematics = [];
            configurations.forEach((configuration) => {
                thematics.push({
                    them_id: configuration.id,
                    them_name: configuration.name,
                    them_definition: [configuration.description],
                    type_sharing: configuration.type_sharing,
                });
            });

            this.manageDashboardService.addThematics(thematics, this.dashboardService);
        });
    }

    openCreateThematicModal() {
        const modal = this.modalService.open(ThematicEditorComponent, { backdrop: 'static', modalDialogClass: 'thematic-modal' });
        modal.componentInstance.gtmTrackCategory = 'filtrage sur verbatim';
        modal.componentInstance.save.subscribe((thematic) => {
            modal.close();
            thematic.lang = this.lang.toUpperCase();
            this.thematicService.postV1Thematic_1(thematic).subscribe((thId) => {
                const newThematicsList = [{
                    them_name: thematic.name,
                    them_type: 'rule',
                    them_definition: thematic.definition,
                    lang: thematic.lang,
                    selected: true,
                    them_id: thId.id,
                    perso: true,
                    type_sharing: '0',
                }, ...(this.dashboardService.currentFilters.getValue().thematics || [])];
                this.manageDashboardService.addThematics(newThematicsList, this.dashboardService);
            });
        });
    }
}
