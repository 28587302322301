import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { ChatMode, Model } from 'app/api/models/conversation';
import { BaseComponent } from 'app/base.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';

export const MODELS: Model[] = [{
    id: 'gpt-35-turbo',
    name: 'GPT-3.5',
}, {
    id: 'gpt-35-turbo-16k',
    name: 'GPT-3.5-16K',
}, {
    id: 'gpt-4o-mini',
    name: 'GPT-4o-mini',
}];

export const DEFAULT_MODEL_ID = 'gpt-4o-mini';
export const DEFAULT_TEMPERATURE = 0.2;
export const DEFAULT_CONTEXT = 20;

@Component({
    selector: 'app-chat-dinootoo',
    templateUrl: './chat-dinootoo.component.html',
    styleUrls: ['./chat-dinootoo.component.scss'],
})
export default class ChatDinootooComponent extends BaseComponent implements OnInit {
    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    // Détermine si on affiche plus de place entre la discussion et le prompt
    get isMoreSpaceForPromptAction() {
        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY && (this.chatService.showValidDiscoveryBtn || this.chatService.showLaunchDiscoveryBtn);
    }

    constructor(
        public chatService: ChatService,
        protected corpusService: CorpusService,
        public manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.chatService.scrollToBottom.subscribe(() => {
            this.scrollToBottom();
        });
    }

    /**
     * Scrolle tout en bas du chat
     */
    public scrollToBottom() {
        setTimeout(() => {
            this.chatContainerBody.nativeElement.scrollTo({
                top: this.chatContainerBody.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
        }, 0);
    }

    /**
     * Evènement lorsque l'on change le mode du chat (conversation ou découverte)
     */
    public onToggleMode(mode: ChatMode) {
        gtmClick({
            track_category: 'dinootoo',
            track_name: 'mode dinootoo découverte IA',
            track_cible: mode === ChatMode.CONVERSATION ? 'dinootoo' : 'découverte IA',
        });
        this.chatService.chatMode.next(mode);
        this.scrollToBottom();
    }
}
