import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import AuthAdminService from 'app/utils/services/auth-admin-service';
import AuthGuardService from 'app/utils/services/authguard.service';
import GdprGuardService from 'app/utils/services/gdprguard.service';
import QuitDbAnalysisService from 'app/utils/services/quit-db-analysis.service';
import RouterTraceService from 'app/utils/services/router-trace.service';
import CorpusAnalysesComponent from './corpus-analyses/corpus-analyses.component';
import CorpusSettingsComponent from './corpus-settings/corpus-settings.component';
import CorpusSettingsAddFiltersComponent from './corpus-settings/corpus-settings-add-filters/corpus-settings-add-filters.component';
import CorpusSettingsDetailsComponent from './corpus-settings/corpus-settings-details/corpus-settings-details.component';
import CorpusSettingsFilesComponent from './corpus-settings/corpus-settings-files/corpus-settings-files.component';
import CorpusSettingsFiltersComponent from './corpus-settings/corpus-settings-filters/corpus-settings-filters.component';
import CorpusSettingsHistoryComponent from './corpus-settings/corpus-settings-history/corpus-settings-history.component';
import CorpusSettingsUsersComponent from './corpus-settings/corpus-settings-users/corpus-settings-users.component';
import CorpusSettingsVerbatimComponent from './corpus-settings/corpus-settings-verbatim/corpus-settings-verbatim.component';
import CorpusTonesComponent from './corpus-tones/corpus-tones.component';
import CorpusComponent from './corpus.component';
import CorpusAiconfigsComponent from './corpus-aiconfigs/corpus-aiconfigs.component';

const CORPUSROUTES: Routes = [
    {
        path: 'project/:id',
        component: CorpusComponent,
        canActivate: [RouterTraceService, AuthGuardService, GdprGuardService],
        children: [
            {
                path: 'tones',
                component: CorpusTonesComponent,
                canActivate: [RouterTraceService],
                canDeactivate: [QuitDbAnalysisService],
                data: {
                    meta: {
                        title: 'VisualCRM Tones',
                    },
                },
            },
            {
                path: 'analyses',
                component: CorpusAnalysesComponent,
                canActivate: [RouterTraceService],
                canDeactivate: [QuitDbAnalysisService],
                data: {
                    meta: {
                        title: 'VisualCRM Analysis',
                    },
                },
            },
            {
                path: 'thematics',
                component: CorpusAiconfigsComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM Thematics',
                    },
                },
            },
            {
                path: 'aiconfigs',
                component: CorpusAiconfigsComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM Ai Configurations',
                    },
                },
            },
            {
                path: 'settings',
                redirectTo: 'settings/files',
            },
            {
                path: 'settings',
                component: CorpusSettingsComponent,
                canActivate: [RouterTraceService, AuthGuardService, GdprGuardService],
                children: [{
                    path: 'details',
                    component: CorpusSettingsDetailsComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Details',
                        },
                    },
                },
                {
                    path: 'files',
                    component: CorpusSettingsFilesComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Files',
                        },
                    },
                },
                {
                    path: 'columns',
                    component: CorpusSettingsFiltersComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Columns',
                        },
                    },
                },
                {
                    path: 'verbatim',
                    component: CorpusSettingsVerbatimComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Verbatim',
                        },
                    },
                },
                {
                    path: 'columns/add',
                    component: CorpusSettingsAddFiltersComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Add columns',
                        },
                    },
                },
                {
                    path: 'users',
                    component: CorpusSettingsUsersComponent,
                    canActivate: [RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM Users',
                        },
                    },
                },
                {
                    path: 'history',
                    component: CorpusSettingsHistoryComponent,
                    canActivate: [AuthAdminService, RouterTraceService],
                    data: {
                        meta: {
                            title: 'VisualCRM History',
                        },
                    },
                }],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(CORPUSROUTES)],
    providers: [AuthAdminService, AuthGuardService, RouterTraceService, GdprGuardService, QuitDbAnalysisService],
    exports: [RouterModule],
})
export default class CorpusRoutingModule {}
