import { IaConfigResponse } from 'app/api/models/iaconfig';

export default class ConfigurationRow {
    constructor(public content: IaConfigResponse) {}

    isCollapsed = true;

    get id() {
        return this.content.id;
    }

    get is_favorite() {
        return this.content.is_favorite;
    }

    get name() {
        return this.content.name;
    }

    get description() {
        return this.content.description;
    }

    get update_date() {
        return (this.content.update_date || this.creation_date || '-').substring(0, 10);
    }

    get creation_date() {
        return (this.content.creation_date || '-').substring(0, 10);
    }

    get shared_used() {
        return this.content.shared_used;
    }

    get shared() {
        return this.perso ? this.content.shared : false;
    }

    get nb_dashboards() {
        return this.content.nb_dashboards;
    }

    get folders() {
        return this.content.folders;
    }

    set folders(folders) {
        this.content.folders = folders;
    }

    get perso() {
        return this.content.perso;
    }

    get origin() {
        return this.content.origin;
    }

    get lang() {
        return this.content.lang;
    }

    get shared_with() {
        return this.content.shared_with || {
            listSelect: [],
            sharedAll: false,
            sharedSelect: false,
        };
    }

    get sharing_date() {
        return (this.content.sharing_date || '-').substring(0, 10);
    }

    get usernameOwner() {
        return this.content.usernameOwner;
    }

    get corpus_using_them() {
        return this.content.corpus_using_them;
    }
}
